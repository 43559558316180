import { useState, useEffect } from 'react';
import esLocale from 'date-fns/locale/es';
import { isBefore } from 'date-fns';
import { useFormik } from 'formik';
import { Swal, SwalConfirm } from '@utils/alerts';

// Material UI
import { Grid, TextField, Button, Card, CardContent, InputAdornment, IconButton } from '@mui/material';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Clear } from '@mui/icons-material';

// Componentes
import LoadingForm from '@components/LinearProgress/LoadingForm';
import AdvancedSelect from '@components/Selects/AdvancedSelect';
import CustomListMultiple from '@components/Lists/CustomListMultiple';

// Utils
import { handleAttrs, floatWithCommas, getDate /*,intWithCommas*/ } from '@utils/Utilities';
import { isEmptyOrNullObject, isTypePhone } from '@utils/validations';

// Servicios
import services from '@services/AportacionesServices';

// Data
import { EditCargosInterface, CargosInterface } from '@interfaces/AportacionesInterfaces';
import { EditCargosSchema, CargosSchema } from '@schemas/AportacionesSchemas';
import { estatus_cargo, estatus_pagado } from '@data/constants/Aportaciones';

// Middleware
import middleware from '@middlewares/middleware';

const CargosForm = (props) => {
  const {
    type = 'create',
    data = null,
    handleDelete = () => {},
    handleClose = () => {},
    handleIsEdited = () => {},
    catalogs,
    isLoadingCat,
  } = props;

  const isEdit = type === 'edit';

  const formik = useFormik({
    initialValues: isEdit ? EditCargosInterface : CargosInterface,
    validationSchema: isEdit ? EditCargosSchema : CargosSchema,
    onSubmit: (values) => {
      if (isEdit) handleEdit(handleAttrs(values));
      else handleCreate(handleAttrs(values));
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMessage, setIsLoadingMessage] = useState('Creando cargo...');
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (!isEmptyOrNullObject(data)) {
      const newData = { ...data };
      delete newData.Aportante;
      delete newData.Celular;
      delete newData.Estatus;
      delete newData.FileURL;

      formik.setValues(newData);
    } // eslint-disable-next-line
  }, [data]);

  const handleDownload = async (id) => {
    try {
      setIsLoading(true);
      setIsLoadingMessage('Descargando cargo...');
      const result = await services.downloadCargos({ id });
      if (!result.results) throw new Error(result.message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = async (params) => {
    const localParams = {
      ...params,
      FechaCargo: params.FechaCargo ? getDate({ fecha: params.FechaCargo }) : null,
      FechaPago: params.FechaPago ? getDate({ fecha: params.FechaPago }) : null,
      MontoCargo: parseFloat(params.MontoCargo.replace(/,/g, '')),
      // Quincena: parseFloat(params.Quincena.replace(/,/g, '')),
    };

    try {
      setIsLoading(true);
      setIsLoadingMessage('Editando cargo...');

      let result = await services.updateCargo(localParams);
      const { success, results, response, message } = result;

      if (success && results) {
        handleIsEdited();
        formik.setValues({ ...formik.values, LineaCaptura: response.referencia });
        SwalConfirm({
          icon: 'success',
          title: message,
          text: `¿Quieres descargar el PDF?`,
          OnConfirm: () => handleDownload(localParams.id),
        });
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreate = async (params) => {
    const localParams = {
      ...params,
      FechaCargo: params.FechaCargo ? getDate({ fecha: params.FechaCargo }) : null,
      FechaPago: params.FechaPago ? getDate({ fecha: params.FechaPago }) : null,
      MontoCargo: parseFloat(params.MontoCargo.replace(/,/g, '')),
      // Quincena: parseFloat(params.Quincena.replace(/,/g, '')),
    };

    try {
      setIsLoading(true);
      setIsLoadingMessage('Creando cargo...');

      let result = await services.createCargo(localParams);
      const { success, results, response, message } = result;

      if (success && results) {
        formik.resetForm();
        setReload(!reload);
        SwalConfirm({
          icon: 'success',
          title: message,
          text: `¿Quieres descargar el PDF?`,
          OnConfirm: () => handleDownload(response.idCargo),
        });
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const loadAll = isLoadingCat;

  return (
    <>
      <LoadingForm
        loadinMessage={isLoadingMessage}
        successMessage="Cargo creado con exito!"
        success={false}
        isLoading={isLoading}
        isOpen={isLoading}
        setIsOpen={() => {}}
      />

      {isEdit ? (
        <Form
          formik={formik}
          loadAll={loadAll}
          isEdit={isEdit}
          handleClose={handleClose}
          handleDelete={handleDelete}
          data={data}
          catalogs={catalogs}
          reload={reload}
        />
      ) : (
        <Card>
          <CardContent>
            <Form
              formik={formik}
              loadAll={loadAll}
              isEdit={isEdit}
              handleClose={handleClose}
              handleDelete={handleDelete}
              data={data}
              catalogs={catalogs}
              reload={reload}
            />
          </CardContent>
        </Card>
      )}
    </>
  );
};

const Form = ({ formik, loadAll, isEdit, handleClose, handleDelete, data, catalogs, reload }) => {
  const [selected, setSelected] = useState({});
  const [initial, setInitial] = useState(true);

  const verify = (name) => {
    if (!formik.touched || !formik.touched[name]) return {};
    if (!formik.errors || !formik.errors[name]) return {};

    return {
      error: formik.touched[name] && Boolean(formik.errors[name]),
      helperText: formik.touched[name] && formik.errors[name],
    };
  };

  const handleUser = (e) => {
    setSelected(e);

    if (isEdit && initial) {
      setInitial(false);
      return;
    }

    formik.setValues({ ...formik.values, idAportante: e.id });
  };
  const handleClear = () => {
    setSelected({});
    formik.setValues({ ...formik.values, idAportante: '' });
  };

  useEffect(() => {
    if (!isEdit) formik.setValues({ ...CargosInterface, FechaCargo: new Date() });
    setSelected({}); // eslint-disable-next-line
  }, [reload]);

  return (
    <Grid container spacing={2}>
      {/* Datos */}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={4}>
            <CustomListMultiple
              id="id_Aportante"
              label="Encuentra y elige al aportante"
              labelNote="Nota: Para realizar una búsqueda debe ingresar un nombre o por el contrario un número de celular."
              API={services.getAportantes}
              APIKeys={{
                input_1: { id: ['ca.Nombre', 'ca.Paterno', 'ca.Materno'], filter: 'LIKE' },
                input_2: { id: 'ca.Celular', filter: '=' },
              }}
              placeholders={{
                input_1: 'Francisco Bueno',
                input_2: '0000000000',
              }}
              initialValues={{
                input_1: data?.Aportante || '',
                input_2: data?.Celular || '',
              }}
              inputValidation={{ input_2: isTypePhone }}
              lengthValidation={{ input_2: { filter: '=', value: 10 } }}
              handleClick={handleUser}
              handleClear={handleClear}
              clearData
              emptySearch
              initialSearch
              selectFirst={isEdit}
              reload={reload}
              disableHeader
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <TextField
                  size="small"
                  fullWidth
                  label="Nombre del Aportante"
                  value={(
                    (selected?.Nombre || '') +
                    ' ' +
                    (selected?.Paterno || '') +
                    ' ' +
                    (selected?.Materno || '')
                  ).trim()}
                  disabled
                  error={verify('idAportante')?.error}
                  helperText={verify('idAportante')?.helperText}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <TextField
                  required
                  size="small"
                  fullWidth
                  name="Nombre"
                  label="Nombre del cargo"
                  placeholder="Nombre del cargo"
                  value={formik.values.Nombre}
                  onChange={formik.handleChange}
                  disabled={loadAll}
                  error={verify('Nombre')?.error}
                  helperText={verify('Nombre')?.helperText}
                />
              </Grid>

              {/*
              <Grid item xs={12} lg={6}>
                <TextField
                  required
                  size="small"
                  fullWidth
                  name="PorcentajeCargo"
                  label="Porcentaje del cargo"
                  placeholder="10.5"
                  value={formik.values.PorcentajeCargo}
                  onChange={({ target: { value, name } }) => {
                    let newValue = value.replace(/[^0-9.]/g, '');
                    if (/^\d{3}$/.test(newValue)) newValue = newValue.slice(0, 2) + '.' + newValue.slice(2);

                    if (newValue.endsWith('.')) newValue = newValue.slice(0, -1);

                    const regex = /^(?:\d{0,2}(?:\.\d{0,2})?)?$/;
                    if (regex.test(newValue)) formik.handleChange({ target: { value: newValue, name } });
                  }}
                  disabled={loadAll}
                  error={verify('PorcentajeCargo')?.error}
                  helperText={verify('PorcentajeCargo')?.helperText}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
              </Grid>
              */}

              <Grid item xs={12} lg={6}>
                <TextField
                  required
                  size="small"
                  fullWidth
                  name="Descripcion"
                  label="Descripción del cargo"
                  placeholder="Este cargo..."
                  value={formik.values.Descripcion}
                  onChange={formik.handleChange}
                  disabled={loadAll}
                  error={verify('Descripcion')?.error}
                  helperText={verify('Descripcion')?.helperText}
                  multiline
                  minRows={2}
                />
              </Grid>

              {/*
              <Grid item xs={12} lg={6}>
                <TextField
                  required
                  size="small"
                  fullWidth
                  name="Quincena"
                  label="Quincena"
                  placeholder="1,000"
                  value={formik.values.Quincena}
                  onChange={({ target: { value, name } }) => {
                    const numericValue = parseFloat(value?.replace(/,/g, '') || '');
                    const monto = numericValue * 0.02;

                    formik.setValues({
                      ...formik.values,
                      [name]: intWithCommas(value),
                      MontoCargo: floatWithCommas(monto + ''),
                    });
                  }}
                  disabled={loadAll}
                  error={verify('Quincena')?.error}
                  helperText={verify('Quincena')?.helperText}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">$</InputAdornment>,
                  }}
                />
              </Grid>
              */}

              <Grid item xs={12} lg={6}>
                <TextField
                  required
                  size="small"
                  fullWidth
                  name="MontoCargo"
                  label="Monto cargo"
                  placeholder="1,000"
                  value={formik.values.MontoCargo}
                  onChange={({ target: { value, name } }) =>
                    formik.setValues({ ...formik.values, [name]: floatWithCommas(value) })
                  }
                  disabled={loadAll}
                  error={verify('MontoCargo')?.error}
                  helperText={verify('MontoCargo')?.helperText}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">$</InputAdornment>,
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                  <MobileDateTimePicker
                    label={`Fecha de cargo *`}
                    value={formik.values.FechaCargo}
                    onChange={(date) => formik.setValues({ ...formik.values, FechaCargo: date ?? '' })}
                    shouldDisableDate={(date) => !isBefore(date, new Date())}
                    inputFormat="yyyy-MM-dd HH:mm:ss"
                    orientation="portrait"
                    disabled={loadAll}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        disabled={loadAll}
                        sx={{ width: '100%' }}
                        error={verify('FechaCargo')?.error}
                        helperText={verify('FechaCargo')?.helperText}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  formik.setValues({ ...formik.values, FechaCargo: new Date() });
                                }}
                                disabled={loadAll}
                              >
                                Hoy
                              </IconButton>

                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  formik.setValues({ ...formik.values, FechaCargo: '' });
                                }}
                                disabled={loadAll}
                              >
                                <Clear />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              {isEdit && (
                <Grid item xs={12} lg={6}>
                  <TextField
                    size="small"
                    fullWidth
                    name="LineaCaptura"
                    label="Linea Captura"
                    placeholder="Linea Captura"
                    value={formik.values.LineaCaptura}
                    onChange={formik.handleChange}
                    disabled={loadAll || true}
                    error={verify('LineaCaptura')?.error}
                    helperText={verify('LineaCaptura')?.helperText}
                  />
                </Grid>
              )}

              <Grid item xs={12} lg={6}>
                <AdvancedSelect
                  required
                  size="small"
                  fullWidth
                  id="idEstatusCargo"
                  name="idEstatusCargo"
                  label="Estatus del cargo *"
                  options={catalogs.cat_aportantes_estatus_cargo?.filter(
                    (item) => item.value !== estatus_cargo.pagado
                  )}
                  value={formik.values.idEstatusCargo}
                  onChange={({ value, name }) => {
                    formik.setValues({
                      ...formik.values,
                      [name]: value,
                      isPagado: value !== estatus_cargo.pagado ? estatus_pagado.no : estatus_pagado.si,
                    });
                  }}
                  isLoading={loadAll}
                  error={verify('idEstatusCargo')?.error}
                  helperText={verify('idEstatusCargo')?.helperText}
                />
              </Grid>

              {/* <Grid item xs={12} lg={6}>
                <AdvancedSelect
                  required
                  size="small"
                  fullWidth
                  id="isPagado"
                  name="isPagado"
                  label="¿Está pagado? *"
                  options={catalogs.cat_si_no}
                  value={formik.values.isPagado}
                  onChange={({ value, name }) => formik.setValues({ ...formik.values, [name]: value })}
                  isLoading={loadAll}
                  error={verify('isPagado')?.error}
                  helperText={verify('isPagado')?.helperText}
                />
              </Grid> */}

              {formik.values.isPagado > 0 && (
                <Grid item xs={12} lg={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                    <MobileDateTimePicker
                      label={`Fecha de pago ${formik.values.isPagado ? ' *' : ''}`}
                      value={formik.values.FechaPago}
                      onChange={(date) => formik.setValues({ ...formik.values, FechaPago: date ?? '' })}
                      shouldDisableDate={(date) => !isBefore(date, new Date())}
                      inputFormat="yyyy-MM-dd HH:mm:ss"
                      orientation="portrait"
                      disabled={loadAll}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                          disabled={loadAll}
                          sx={{ width: '100%' }}
                          error={verify('FechaPago')?.error}
                          helperText={verify('FechaPago')?.helperText}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    formik.setValues({ ...formik.values, FechaPago: new Date() });
                                  }}
                                  disabled={loadAll}
                                >
                                  Hoy
                                </IconButton>

                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    formik.setValues({ ...formik.values, FechaPago: '' });
                                  }}
                                  disabled={loadAll}
                                >
                                  <Clear />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              )}

              <Grid item xs={12}>
                <Grid container spacing={2} justifyContent={'end'}>
                  {isEdit && (
                    <Grid item xs={12} sm={5} md={4} lg={3}>
                      <Button variant="contained" color="warning" onClick={handleClose} fullWidth>
                        Cancelar
                      </Button>
                    </Grid>
                  )}

                  {isEdit && middleware.checkMenuAction('Eliminar') && (
                    <Grid item xs={12} sm={5} md={4} lg={3}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleDelete({ ...data, LineaCaptura: formik.values.LineaCaptura })}
                        fullWidth
                      >
                        Eliminar
                      </Button>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={5} md={4} lg={3}>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={formik.handleSubmit}
                      disabled={loadAll}
                      fullWidth
                    >
                      {isEdit ? 'Editar' : 'Agregar'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CargosForm;
