import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Swal } from '@utils/alerts';

// Material UI
import { Grid, TextField, Button, Card, CardContent, InputAdornment } from '@mui/material';

// Componentes
import PerfilForm from '@components/Aportaciones/PerfilForm';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import AdvancedSelect from '@components/Selects/AdvancedSelect';
import CustomListMultiple from '@components/Lists/CustomListMultiple';
import CustomModal from '@components/Modal/CustomModal';

// Utils
import { handleAttrs, floatWithCommas } from '@utils/Utilities';
import { isEmptyOrNullObject, isTypePhone } from '@utils/validations';

// Servicios
import services from '@services/AportacionesServices';

// Data
import { EditPercepcionesInterface, PercepcionesInterface } from '@interfaces/AportacionesInterfaces';
import { EditPercepcionesSchema, PercepcionesSchema } from '@schemas/AportacionesSchemas';

// Middleware
import middleware from '@middlewares/middleware';

const PercepcionesForm = (props) => {
  const {
    type = 'create',
    data = null,
    handleDelete = () => {},
    handleClose = () => {},
    handleIsEdited = () => {},
    reloadCatalogs = () => {},
    catalogs,
    isLoadingCat,
  } = props;

  const isEdit = type === 'edit';

  const formik = useFormik({
    initialValues: isEdit ? EditPercepcionesInterface : PercepcionesInterface,
    validationSchema: isEdit ? EditPercepcionesSchema : PercepcionesSchema,
    onSubmit: (values) => {
      if (isEdit) handleEdit(handleAttrs(values));
      else handleCreate(handleAttrs(values));
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMessage, setIsLoadingMessage] = useState('Creando percepción...');
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (!isEmptyOrNullObject(data)) {
      const newData = { ...data };
      delete newData.Aportante;
      delete newData.Celular;
      delete newData.PerfilAportador;

      formik.setValues(newData);
    } // eslint-disable-next-line
  }, [data]);

  const handleEdit = async (params) => {
    const localParams = {
      ...params,
      MontoNeto: parseFloat(params.MontoNeto.replace(/,/g, '')),
    };

    try {
      setIsLoading(true);
      setIsLoadingMessage('Editando percepción...');

      const result = await services.updatePercepcion(localParams);
      const { success, results, message } = result;

      if (success && results) {
        handleIsEdited();
        Swal.fire({ title: message, icon: 'success' });
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreate = async (params) => {
    const localParams = {
      ...params,
      MontoNeto: parseFloat(params.MontoNeto.replace(/,/g, '')),
    };

    try {
      setIsLoading(true);
      setIsLoadingMessage('Creando percepción...');

      const result = await services.createPercepcion(localParams);
      const { success, results, message } = result;

      if (success && results) {
        Swal.fire({ title: message, icon: 'success' });
        formik.resetForm();
        setReload(!reload);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const loadAll = isLoadingCat;

  return (
    <>
      <LoadingForm
        loadinMessage={isLoadingMessage}
        successMessage="Percepcion creada con exito!"
        success={false}
        isLoading={isLoading}
        isOpen={isLoading}
        setIsOpen={() => {}}
      />

      {isEdit ? (
        <Form
          formik={formik}
          loadAll={loadAll}
          isEdit={isEdit}
          handleClose={handleClose}
          handleDelete={handleDelete}
          reloadCatalogs={reloadCatalogs}
          data={data}
          catalogs={catalogs}
          reload={reload}
        />
      ) : (
        <Card>
          <CardContent>
            <Form
              formik={formik}
              loadAll={loadAll}
              isEdit={isEdit}
              handleClose={handleClose}
              handleDelete={handleDelete}
              reloadCatalogs={reloadCatalogs}
              data={data}
              catalogs={catalogs}
              reload={reload}
            />
          </CardContent>
        </Card>
      )}
    </>
  );
};

const Form = (props) => {
  const { formik, loadAll, isEdit, handleClose, handleDelete, reloadCatalogs, data, catalogs, reload } =
    props;

  const [selected, setSelected] = useState({});
  const [initial, setInitial] = useState(true);

  const [newPerfil, setNewPerfil] = useState({});

  const verify = (name) => {
    if (!formik.touched || !formik.touched[name]) return {};
    if (!formik.errors || !formik.errors[name]) return {};

    return {
      error: formik.touched[name] && Boolean(formik.errors[name]),
      helperText: formik.touched[name] && formik.errors[name],
    };
  };

  const handleUser = (e) => {
    setSelected(e);

    if (isEdit && initial) {
      setInitial(false);
      return;
    }

    formik.setValues({ ...formik.values, idAportante: e.id });
  };
  const handleClear = () => {
    setSelected({});
    formik.setValues({ ...formik.values, idAportante: '' });
  };

  const handleAddItem = ({ value }) => {
    setNewPerfil({ PerfilAportador: value, Porcentaje: 2 });
  };

  const resetNewPerfil = () => setNewPerfil({});

  const handleIsCreated = (id) => {
    reloadCatalogs();
    setNewPerfil({});
    formik.setValues({ ...formik.values, idPerfil: id });
  };

  useEffect(() => {
    setSelected({}); // eslint-disable-next-line
  }, [reload]);

  return (
    <Grid container spacing={2}>
      <CustomModal
        open={!isEmptyOrNullObject(newPerfil)}
        onClose={resetNewPerfil}
        title="Añadir Perfil"
        fullWidth
        disableClose
      >
        <PerfilForm
          type="add_modal"
          data={newPerfil}
          handleClose={resetNewPerfil}
          handleIsCreated={handleIsCreated}
        />
      </CustomModal>

      {/* Datos */}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={4}>
            <CustomListMultiple
              id="id_Aportante"
              label="Encuentra y elige al aportante"
              labelNote="Nota: Para realizar una búsqueda debe ingresar un nombre o por el contrario un número de celular."
              API={services.getAportantes}
              APIKeys={{
                input_1: { id: ['ca.Nombre', 'ca.Paterno', 'ca.Materno'], filter: 'LIKE' },
                input_2: { id: 'ca.Celular', filter: '=' },
              }}
              placeholders={{
                input_1: 'Francisco Bueno',
                input_2: '0000000000',
              }}
              initialValues={{
                input_1: data?.Aportante || '',
                input_2: data?.Celular || '',
              }}
              inputValidation={{ input_2: isTypePhone }}
              lengthValidation={{ input_2: { filter: '=', value: 10 } }}
              handleClick={handleUser}
              handleClear={handleClear}
              clearData
              emptySearch
              initialSearch
              selectFirst={isEdit}
              reload={reload}
              disableHeader
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <TextField
                  size="small"
                  fullWidth
                  label="Nombre del Aportante"
                  value={(
                    (selected?.Nombre || '') +
                    ' ' +
                    (selected?.Paterno || '') +
                    ' ' +
                    (selected?.Materno || '')
                  ).trim()}
                  disabled
                  error={verify('idAportante')?.error}
                  helperText={verify('idAportante')?.helperText}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <AdvancedSelect
                  required
                  size="small"
                  fullWidth
                  id="idPerfil"
                  name="idPerfil"
                  label="Perfil *"
                  options={catalogs.cat_aportantes_perfil}
                  value={formik.values.idPerfil}
                  onChange={({ value, name }) => formik.setValues({ ...formik.values, [name]: value })}
                  isLoading={loadAll}
                  error={verify('idPerfil')?.error}
                  helperText={verify('idPerfil')?.helperText}
                  isSearchable
                  handleAddItem={handleAddItem}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <TextField
                  required
                  size="small"
                  fullWidth
                  name="Puesto"
                  label="Puesto"
                  placeholder="Puesto"
                  value={formik.values.Puesto}
                  onChange={formik.handleChange}
                  disabled={loadAll}
                  error={verify('Puesto')?.error}
                  helperText={verify('Puesto')?.helperText}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <TextField
                  required
                  size="small"
                  fullWidth
                  name="Dependencia"
                  label="Dependencia"
                  placeholder="Dependencia"
                  value={formik.values.Dependencia}
                  onChange={formik.handleChange}
                  disabled={loadAll}
                  error={verify('Dependencia')?.error}
                  helperText={verify('Dependencia')?.helperText}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <TextField
                  required
                  size="small"
                  fullWidth
                  name="MontoNeto"
                  label="Monto neto"
                  placeholder="1,000"
                  value={formik.values.MontoNeto}
                  onChange={({ target: { value, name } }) =>
                    formik.setValues({ ...formik.values, [name]: floatWithCommas(value) })
                  }
                  disabled={loadAll}
                  error={verify('MontoNeto')?.error}
                  helperText={verify('MontoNeto')?.helperText}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">$</InputAdornment>,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2} justifyContent={'end'}>
                  {isEdit && (
                    <Grid item xs={12} sm={5} md={4} lg={3}>
                      <Button variant="contained" color="warning" onClick={handleClose} fullWidth>
                        Cancelar
                      </Button>
                    </Grid>
                  )}

                  {isEdit && middleware.checkMenuAction('Eliminar') && (
                    <Grid item xs={12} sm={5} md={4} lg={3}>
                      <Button variant="contained" color="error" onClick={() => handleDelete(data)} fullWidth>
                        Eliminar
                      </Button>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={5} md={4} lg={3}>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={formik.handleSubmit}
                      disabled={loadAll}
                      fullWidth
                    >
                      {isEdit ? 'Editar' : 'Agregar'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PercepcionesForm;
