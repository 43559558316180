import { Fragment } from 'react';

// Material UI
import { ListItem, ListItemButton, ListItemAvatar, Avatar, Icon, ListItemText, Divider } from '@mui/material';

const DefaultListItem = ({ item, index, selected, handleSelected, disabled = false, itemValues = {} }) => {
  const {
    primary = 'item.Nombre item.Paterno',
    secondary = 'item.email',
    icon = 'account_circle',
    disableIcon = false,
  } = itemValues;

  const getValue = (item, values = '') => {
    const result = values.replace(/item\.(\w+)/g, (match, p1) => {
      return item[p1] || '';
    });
    return result;
  };

  return (
    <Fragment>
      <ListItem disablePadding>
        <ListItemButton
          selected={selected === index}
          onClick={() => handleSelected(item, index)}
          disabled={disabled}
        >
          {!disableIcon && (
            <ListItemAvatar sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Avatar>
                <Icon sx={{ fontSize: 40 }}>{icon}</Icon>
              </Avatar>
            </ListItemAvatar>
          )}

          <ListItemText primary={getValue(item, primary)} secondary={getValue(item, secondary)} />
        </ListItemButton>
      </ListItem>

      <Divider variant="inset" component="li" />
    </Fragment>
  );
};

export default DefaultListItem;
