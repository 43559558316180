import { useState, useEffect } from 'react';
import { Swal } from '@utils/alerts';

// Material UI
import { Card, CardContent } from '@mui/material';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import CustomTabs from '@components/Tabs/CustomTabs';
import Form from '@components/Aportaciones/PagosForm';
import View from '@components/Aportaciones/PagosView';

// Middleware
import middleware from '@middlewares/middleware';

// Servicios
import CatalogService from '@services/CatalogServices';

const Pagos = () => {
  const add = middleware.checkMenuAction('Agregar');

  const [tab, setTab] = useState(0);
  const [catalogs, setCatalogs] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCatalogs();
  }, []);

  const getCatalogs = async () => {
    const params = [
      { id: 'cat_aportantes_estatus_cargo' },
      { id: 'cat_aportantes_tipo_pago' },
      { id: 'cat_aportantes_bancos' },
    ];

    try {
      setIsLoading(true);
      const result = await CatalogService.getCatalogs(params);
      const { success, results, response, message } = result;

      if (success && results)
        setCatalogs({
          ...response.catalogs,
          cat_si_no: [
            { value: 1, label: 'SI' },
            { value: 0, label: 'NO' },
          ],
        });
      else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AdminLayout>
      <ActionHeader title="Pagos" />

      <Card className="card-primary">
        <CardContent>
          <CustomTabs
            value={tab}
            setValue={setTab}
            tabs={[
              {
                value: 0,
                label: 'Listado',
                icon: 'view_list',
                sxContent: { pt: 0 },
                component: <View catalogs={catalogs} isLoadingCat={isLoading} reloadCatalogs={getCatalogs} />,
              },
              {
                value: 1,
                label: 'Agregar',
                icon: 'add',
                sxContent: { pt: 0 },
                component: <Form catalogs={catalogs} isLoadingCat={isLoading} reloadCatalogs={getCatalogs} />,
                display: add,
              },
            ]}
          />
        </CardContent>
      </Card>
    </AdminLayout>
  );
};

export default Pagos;
