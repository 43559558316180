// Estatus pago
export const estatus_referencia = { activa: 1, cancelada: 2, caducada: 3, finalizada: 4, agrupada: 5 };
export const estatus_cargo = {
  pendiente: 1,
  comprobante_rechazado: 2,
  en_revision: 3,
  pagado: 4,
  sustituido: 5,
};
export const estatus_pagado = { si: 1, no: 0 };

// Tablas

const actions = { id: 'actions', label: 'Acciones', sort: false, align: 'center', disablePadding: true };

export const COLUMNS_APORTANTES = (value) => {
  const data = [
    { id: 'RNM', label: 'RNM', type: 'text' },
    { id: 'Municipio', label: 'Municipio', type: 'text' },
    { id: 'Nombre', label: 'Nombre', type: 'text' },
    { id: 'Celular', label: 'Celular', type: 'text' },
    { id: 'Correo', label: 'Correo', type: 'text' },
  ];
  if (value) data.push(actions);
  return data;
};

export const COLUMNS_PERFIL = (value) => {
  const data = [
    { id: 'PerfilAportador', label: 'Perfil', type: 'text' },
    { id: 'Porcentaje', label: 'Porcentaje', type: 'percent', fixed: 2 },
  ];
  if (value) data.push(actions);
  return data;
};

export const COLUMNS_PERCEPCIONES = (value) => {
  const data = [
    { id: 'Aportante', label: 'Aportante', type: 'text' },
    { id: 'Celular', label: 'Celular', type: 'text' },
    { id: 'PerfilAportador', label: 'Perfil', type: 'text' },
    { id: 'Puesto', label: 'Puesto', type: 'text' },
    { id: 'Dependencia', label: 'Dependencia', type: 'text' },
    { id: 'MontoNeto', label: 'Monto Neto', type: 'number' },
  ];
  if (value) data.push(actions);
  return data;
};

export const COLUMNS_CARGOS = (value) => {
  const data = [
    { id: 'Aportante', label: 'Aportante', type: 'text' },
    { id: 'Celular', label: 'Celular', type: 'text' },
    { id: 'Nombre', label: 'Nombre', type: 'text' },
    { id: 'Quincena', label: 'Quincena', type: 'number' },
    { id: 'MontoCargo', label: 'Monto Cargo', type: 'number' },
    { id: 'FechaCargo', label: 'Fecha Cargo', type: 'text' },
    { id: 'LineaCaptura', label: 'Linea Captura', type: 'text' },
    { id: 'Estatus', label: 'Estatus', type: 'text' },
    { id: 'FechaPago', label: 'Fecha Pago', type: 'text' },
    { id: 'Comprobante', label: 'Comprobante', type: 'text' },
  ];
  if (value) data.push(actions);
  return data;
};

export const COLUMNS_MIS_CARGOS = (value) => {
  const data = [
    { id: 'Nombre', label: 'Nombre', type: 'text' },
    { id: 'MontoCargo', label: 'Monto Cargo', type: 'number' },
    { id: 'FechaCargo', label: 'Fecha Cargo', type: 'text' },
    { id: 'LineaCaptura', label: 'Linea Captura', type: 'text' },
    { id: 'Estatus', label: 'Estatus', type: 'text' },
    { id: 'FechaPago', label: 'Fecha Pago', type: 'text' },
    { id: 'Comprobante', label: 'Comprobante', type: 'text' },
  ];
  if (value) data.push(actions);
  return data;
};

export const COLUMNS_PAGOS = (value) => {
  const data = [
    { id: 'Aportante', label: 'Aportante', type: 'text' },
    { id: 'Celular', label: 'Celular', type: 'text' },
    { id: 'Nombre', label: 'Nombre Cargo', type: 'text' },
    { id: 'FechaCargo', label: 'Fecha Cargo', type: 'text' },
    { id: 'Estatus', label: 'Estatus', type: 'text' },
    { id: 'MontoPagado', label: 'Monto Pagado', type: 'number' },
    { id: 'FechaPago', label: 'Fecha Pago', type: 'text' },
    { id: 'TipoPago', label: 'Tipo Pago', type: 'text' },
    { id: 'Banco', label: 'Banco', type: 'text' },
    { id: 'Comprobante', label: 'Comprobante', type: 'text' },
  ];
  if (value) data.push(actions);
  return data;
};
