import { useState, useEffect } from 'react';
import { parseISO } from 'date-fns';

// Material UI
import { Card, Grid, IconButton, Icon, Box, CardContent, Typography, Button } from '@mui/material';

// Componentes
import CustomTable from '@components/Tables/CustomTable';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import Form from '@components/Aportaciones/PagosForm';
import Filter from '@components/Aportaciones/Filter';
import CustomModal from '@components/Modal/CustomModal';

// Servicios
import services from '@services/AportacionesServices';
import { COLUMNS_PAGOS } from '@data/constants/Aportaciones';

// Middleware
import middleware from '@middlewares/middleware';

// Utils
import { Swal, SwalDelete, SwalImage } from '@utils/alerts';
import { isEmptyOrInvalidString } from '@utils/validations';

const PagosView = (props) => {
  const { catalogs, isLoadingCat, reloadCatalogs = () => {} } = props;

  const canEdit = middleware.checkMenuAction('Editar');
  const canDelete = middleware.checkMenuAction('Eliminar');

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const [open, setOpen] = useState(false);
  const [currentData, setCurrentData] = useState({});

  const [params, setParams] = useState({ page: 0, pageSize: 10, filtered: [], sorted: [] });

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    getData(params); // eslint-disable-next-line
  }, [params]);

  const getData = async (params) => {
    try {
      function formatNumber(value) {
        if (!value) return '';
        const number = parseFloat(value);
        if (isNaN(number)) return value;
        if (!number) return '';
        if (number % 1 === 0) return number.toFixed(0);
        return parseFloat(number.toFixed(2)).toString();
      }

      setIsLoading(true);

      const result = await services.getPagos(params);
      const { results, response, message } = result;

      if (results) {
        const style = { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: 14 };

        const format = response.data.map((item) => {
          const haveImage = !isEmptyOrInvalidString(item.FileURL);

          return {
            ...item,
            Aportante: <Typography sx={{ maxWidth: 200, ...style }}>{item.Aportante?.trim()}</Typography>,
            FechaCargo: <Typography sx={{ width: 90, fontSize: 14 }}>{item.FechaCargo?.trim()}</Typography>,
            FechaPago: <Typography sx={{ width: 90, fontSize: 14 }}>{item.FechaPago?.trim()}</Typography>,
            Comprobante: (
              <Button
                fullWidth
                color="success"
                variant="outlined"
                onClick={() => (haveImage ? SwalImage({ image: item.FileURL, title: item.Nombre }) : {})}
                sx={{ whiteSpace: 'nowrap' }}
                disabled={!haveImage}
              >
                {haveImage ? 'Ver comprobante' : 'Sin comprobante'}
              </Button>
            ),

            actions: (
              <Box sx={{ display: 'flex', justifyContent: 'end', gap: 1 }}>
                {canEdit && (
                  <IconButton
                    aria-label="edit"
                    color="success"
                    onClick={() =>
                      handleEdit({
                        ...item,
                        FechaPago: item.FechaPago ? parseISO(item.FechaPago) : '',
                        MontoPagado: item.MontoPagado ? formatNumber(item.MontoPagado) : '',
                      })
                    }
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                )}
                {canDelete && (
                  <IconButton aria-label="delete" color="error" onClick={() => deleteConfirmation(item)}>
                    <Icon>delete</Icon>
                  </IconButton>
                )}
              </Box>
            ),
          };
        });

        setData(format);
        setTotal(response.total);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning', customClass: { container: 'modal-alert' } });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => setOpen(false);

  const handleEdit = (values) => {
    setOpen(true);
    setCurrentData(values);
  };

  const handleIsEdited = () => setParams((prev) => ({ ...prev, page: 0 }));

  const deleteConfirmation = async ({ Aportante, Nombre, id, idCargo }) => {
    SwalDelete({
      title: 'Eliminar Pago',
      text: `¿Estas seguro de querer eliminar el pago del aportante: ${Aportante} con el cargo: '${Nombre}'?`,
      buttonText: 'Eliminar pago',
      onDelete: () => handleDelete({ id, idCargo }),
    });
  };

  const handleDelete = async ({ id, idCargo }) => {
    try {
      setIsDeleting(true);

      const result = await services.deletePago({ id, idCargo });
      const { results, message } = result;

      if (results) {
        Swal.fire({ title: message, icon: 'success', customClass: { container: 'modal-alert' } });
        handleClose();
        getData(params);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning', customClass: { container: 'modal-alert' } });
    } finally {
      setIsDeleting(false);
    }
  };

  const handlePagination = ({ page, pageSize }) => setParams((prev) => ({ ...prev, page, pageSize }));

  const handleSort = ({ orderBy, order }) => {
    const filters = {
      Aportante: "CONCAT(ca.Nombre, ' ', ca.Paterno, ' ', ca.Materno)",
      Celular: 'ca.Celular',
      Nombre: 'cac.Nombre',
      FechaCargo: 'cac.FechaCargo',
      Estatus: 'caec.id',
      MontoPagado: 'capa.MontoPagado',
      FechaPago: 'capa.FechaPago',
      TipoPago: 'capa.idTipoPago',
      Banco: 'capa.Banco',
    };

    setParams((prev) => ({ ...prev, sorted: [{ id: filters[orderBy], value: order }] }));
  };

  const handleFilter = (values) => setParams((prev) => ({ ...prev, filtered: values }));

  return (
    <Card>
      <LoadingForm
        loadinMessage="Eliminado Pago"
        successMessage="Pago eliminado con exito!"
        success={false}
        isLoading={isDeleting}
        isOpen={isDeleting}
        setIsOpen={() => {}}
      />

      <CustomModal open={open} onClose={handleClose} title="Editar Pago" fullWidth disableClose>
        <Form
          type="edit"
          data={currentData}
          handleDelete={deleteConfirmation}
          handleClose={handleClose}
          handleIsEdited={handleIsEdited}
          reloadCatalogs={reloadCatalogs}
          catalogs={catalogs}
          isLoadingCat={isLoadingCat}
        />
      </CustomModal>

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Filter
              handleFilter={handleFilter}
              catalogs={catalogs}
              isLoadingCat={isLoadingCat}
              flags={{ nombre: true, celular: true, cat_tipo_pago: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTable
              rows={data}
              columns={COLUMNS_PAGOS(canEdit || canDelete)}
              total={total}
              page={params.page}
              pageSize={params.pageSize}
              isLoading={isLoading}
              handlePagination={handlePagination}
              handleSort={handleSort}
              disableCardType
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PagosView;
