import { useState, useEffect, useCallback, Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';
import { Swal } from '@utils/alerts';

// Material UI
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Icon,
  Typography,
  Alert,
  Grid,
  TextField,
  IconButton,
  Button,
  List,
  LinearProgress,
  CircularProgress,
} from '@mui/material';

// Componentes
import DefaultListItem from '@components/Lists/DefaultListItem';

const CustomListMultiple = (props) => {
  const {
    id = 'timeline-container',
    API = () => ({ results: false, message: 'Ingresa una API' }),
    APIKeys = { input_1: { id: 'name', filter: 'LIKE' } },
    APIFilter = {},
    APISort = {},
    pageSize = 20,
    label, // "Encuentra al usuario"
    labelNote, // "Nota: Debe ingresar un nombre"
    header = { icon: 'list_alt', title: 'Listado' }, // false
    config = { height: 400, endMessage: 'No hay registros para mostrar' },
    breaks = { sm: 12, md: 6 },
    buttonsBreaks = { sm: 12, md: 4 },
    listBreaks = { sm: 12 },
    initialValues = { input_1: '' },
    labels = {}, // { input_2: "Buscar por celular"}
    placeholders = {}, // { input_2: "Ingresa el celular"}
    inputValidation = {}, // { input_2: isTypePhone }
    inputFormat = {}, // { input_2: limpiarTelefono }
    lengthValidation = {}, // { input_2: { filter: "=", value: 10 } }
    itemComponent = DefaultListItem,
    itemValues = {},
    setLoading = () => {},
    setLoadingHasMore = () => {},
    handleClick = () => {},
    handleAction = () => {},
    handleClear = () => {},
    headerComponent = null,
    initialSearch = false,
    emptySearch = false,
    selectFirst = false,
    clearData = false,
    disabled = false,
    disableHeader = false,
    disableFinder = false,
    disableCardType = false,
    reload,
  } = props;

  const initialParams = { page: 0, pageSize, filtered: [], sorted: [] };
  const inputsLength = Object?.keys(APIKeys)?.length || 0;

  // Generar inputs apartir de APIKeys
  const initialInputsKeys = Object.keys(APIKeys).reduce((acc, key) => {
    acc[key] = '';
    return acc;
  }, {});

  const siv = Object.keys(APIKeys).reduce((acc, key) => {
    acc[key] = initialValues[key] || '';
    return acc;
  }, {});

  const [localLoading, setLocalLoading] = useState(false);
  const [localLoadingHasMore, setLocalLoadingHasMore] = useState(false);

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [showedItems, setShowedItems] = useState(0);

  const [hasMore, setHasMore] = useState(false);
  const [params, setParams] = useState(initialParams);

  const [selected, setSelected] = useState('');
  const [searchInputs, setSearchInputs] = useState({ input_1: '', ...siv });

  useEffect(() => {
    setSelected('');
    setSearchInputs({ input_1: '', ...siv });
    if (initialSearch) fetchAPI(siv, true);
    // eslint-disable-next-line
  }, [reload, initialSearch]);

  const fetchAPI = async (inputs, reload = false) => {
    try {
      setData([]);
      setLoading(true);
      setLocalLoading(true);

      const localParams = { ...initialParams };

      if (APIFilter?.start?.length) localParams.filtered.push(...APIFilter?.start);
      if (APISort?.start?.length) localParams.sorted.push(...APISort?.start);

      const filtered = Object.keys(inputs).reduce((acc, key) => {
        const value = inputs[key];
        if (value) {
          const filter = APIKeys[key]?.filter || 'LIKE';

          acc.push({
            id: APIKeys[key]?.id || `field${key + 1}`,
            filter,
            value: filter === 'LIKE' ? `%${value}%` : value,
          });
        }
        return acc;
      }, []);
      if (filtered?.length) localParams.filtered.push(...filtered);

      if (APIFilter?.end?.length) localParams.filtered.push(...APIFilter?.end);
      if (APISort?.end?.length) localParams.sorted.push(...APISort?.end);

      setParams(localParams);

      const res = await API(localParams);
      const { results, response, message } = res;

      if (results) {
        const data = response.data ?? [];
        const total = response.total;

        setData(data);
        setShowedItems(data?.length ?? 0);
        setTotal(total);

        if (selectFirst) handleSelected(data[0], 0);

        if (data?.length < total) setHasMore(true);
        else setHasMore(false);
      } else throw new Error(message);
    } catch (e) {
      setData([]);
      setShowedItems(0);
      setTotal(0);
      setHasMore(false);
      setParams(initialParams);
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setLoading(false);
      setLocalLoading(false);
    }
  };

  const fetchHasMore = useCallback(async () => {
    if (!localLoadingHasMore && data.length < total) {
      try {
        setLoadingHasMore(true);
        setLocalLoadingHasMore(true);

        const localParams = params;
        localParams.page = params.page + 1;

        setParams(localParams);

        const res = await API(localParams);
        const { results, response, message } = res;

        if (results) {
          const localData = data.concat(response.data ?? []);
          const total = response.total;

          setData(localData);
          setShowedItems(localData?.length ?? 0);
          setTotal(total);

          if (localData?.length < total) setHasMore(true);
          else setHasMore(false);
        } else throw new Error(message);
      } catch (e) {
        setData([]);
        setShowedItems(0);
        setTotal(0);
        setHasMore(false);
        setParams(initialParams);
        Swal.fire({ title: e.message, icon: 'warning' });
      } finally {
        setLoadingHasMore(false);
        setLocalLoadingHasMore(false);
      }
    }
    // eslint-disable-next-line
  }, [localLoadingHasMore, data, total, params, setLoadingHasMore, API]);

  const handleSelected = (data, selected) => {
    setSelected(selected);
    handleClick(data);
  };

  const invalidLength = (value, validation) => {
    const localValue = validation?.value;
    const filter = validation?.filter ?? '=';

    if (localValue && value) {
      switch (filter) {
        case '=':
          if (!(value.length === localValue))
            return { result: true, message: `Debe tener una longitud igual a ${localValue}` };
          break;
        case '>':
          if (!(value.length > localValue))
            return { result: true, message: `Debe tener una longitud mayor a ${localValue}` };
          break;
        case '<':
          if (!(value.length < localValue))
            return { result: true, message: `Debe tener una longitud menor a ${localValue}` };
          break;
        case '!=':
          if (!(value.length !== localValue))
            return { result: true, message: `Debe tener una longitud diferente a ${localValue}` };
          break;
        case '>=':
          if (!(value.length >= localValue))
            return { result: true, message: `Debe tener una longitud mayor o igual a ${localValue}` };
          break;
        case '<=':
          if (!(value.length <= localValue))
            return { result: true, message: `Debe tener una longitud menor o igual a ${localValue}` };
          break;
        default:
          if (!(value.length === localValue))
            return { result: true, message: `Debe tener una longitud igual a ${localValue}` };
          break;
      }
    }

    return { result: false, message: `` };
  };

  const handleSubmit = (inputs) => {
    const search = !(emptySearch || initialSearch);

    // Valida todos los inputs
    for (const [key, value] of Object.entries(inputs)) {
      const validationRule = lengthValidation?.[key];
      if (invalidLength(value, validationRule).result) return;
    }

    // Ve si todos los inputs están vacíos
    const allInputsEmpty = Object.values(inputs).every((input) => input === '');
    if (allInputsEmpty && search) return;

    const container = document.getElementsByClassName(id);
    if (container) container[0].scrollTo({ top: 0, behavior: 'smooth' });

    setSelected('');
    fetchAPI(inputs);
  };

  const handleLocalClear = () => {
    if (Object.values(searchInputs).every((input) => input === '')) return;
    setSearchInputs(initialInputsKeys);

    setSelected('');
    handleClear();

    if (clearData) {
      setData([]);
      setShowedItems(0);
      setTotal(0);
      setHasMore(false);
      setParams(initialParams);
    }

    if (!(emptySearch || initialSearch)) return;

    fetchAPI(initialInputsKeys);
  };

  const handleInput = (val, input) => {
    const { [input]: validation = () => true } = inputValidation;
    const { [input]: format = (e) => e } = inputFormat;
    const value = format(val);

    if (validation(value)) setSearchInputs((prev) => ({ ...prev, [input]: value }));
    if (value.length < 1 && emptySearch) handleSubmit({ ...searchInputs, [input]: value });
  };

  const findLabel = (value) => {
    const inicial = 'Buscar';
    const number = parseInt(value);

    switch (number) {
      case 1:
        return inicial + ' por usuario';
      case 2:
        return inicial + ' por celular';
      default:
        return inicial + '...';
    }
  };

  return (
    <Box
      sx={{ width: '100%', mb: '0 !important' }}
      component={!disableCardType ? Card : Box}
      className={!disableCardType ? 'card-primary' : ''}
    >
      {header && !disableHeader && (
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <CardHeader
              avatar={<Icon>{header?.icon ?? 'list_alt'}</Icon>}
              title={header?.title ?? 'Listado'}
            />
          </Box>
          {headerComponent && <Box mr={2}>{headerComponent}</Box>}
        </Box>
      )}

      <CardContent sx={{ pt: !disableHeader ? 0 : 2 }}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sm={listBreaks?.sm ? 12 - listBreaks?.sm : undefined}
            md={listBreaks?.md ? 12 - listBreaks?.md : undefined}
            lg={listBreaks?.lg ? 12 - listBreaks?.lg : undefined}
            xl={listBreaks?.xl ? 12 - listBreaks?.xl : undefined}
            sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
          >
            {(label || labelNote) && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {label && (
                  <Typography variant="subtitle1" fontWeight={600}>
                    {label}
                  </Typography>
                )}
                {labelNote && (
                  <Alert variant="outlined" severity="info" sx={{ borderColor: 'info.main', mb: 1 }}>
                    {labelNote}
                  </Alert>
                )}
              </Box>
            )}

            {!disableFinder && (
              <Grid container spacing={1}>
                {Object.keys(searchInputs).map((key) => {
                  const number = key.match(/\d+/);

                  const sizes =
                    inputsLength === 1
                      ? {}
                      : { sm: breaks?.sm, md: breaks?.md, lg: breaks?.lg, xl: breaks?.xl };

                  return (
                    <Grid item xs={12} {...sizes} key={key}>
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        label={labels?.[key] ?? findLabel(number)}
                        placeholder={placeholders?.[key] ?? ''}
                        value={searchInputs[key]}
                        onChange={(e) => handleInput(e.target.value, key)}
                        error={invalidLength(searchInputs[key], lengthValidation?.[key])?.result}
                        helperText={invalidLength(searchInputs[key], lengthValidation?.[key])?.message}
                        disabled={disabled}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            handleSubmit(searchInputs);
                          }
                        }}
                        {...(inputsLength === 1
                          ? {
                              InputProps: {
                                endAdornment: (
                                  <IconButton aria-label="search" onClick={() => handleSubmit(searchInputs)}>
                                    <Icon>search</Icon>
                                  </IconButton>
                                ),
                              },
                            }
                          : {})}
                      />
                    </Grid>
                  );
                })}

                {/* Botones */}
                {inputsLength > 1 && (
                  <Grid item xs={12} container spacing={1} sx={{ justifyContent: 'end' }}>
                    <Grid
                      item
                      xs={12}
                      sm={buttonsBreaks?.sm}
                      md={buttonsBreaks?.md}
                      lg={buttonsBreaks?.lg}
                      xl={buttonsBreaks?.xl}
                    >
                      <Button
                        fullWidth
                        size="small"
                        color="primaryDark"
                        variant="contained"
                        startIcon={<Icon>search</Icon>}
                        onClick={() => handleSubmit(searchInputs)}
                        disabled={disabled}
                      >
                        Buscar
                      </Button>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={buttonsBreaks?.sm}
                      md={buttonsBreaks?.md}
                      lg={buttonsBreaks?.lg}
                      xl={buttonsBreaks?.xl}
                    >
                      <Button
                        fullWidth
                        size="small"
                        color="primaryDark"
                        variant="outlined"
                        onClick={handleLocalClear}
                        disabled={disabled}
                      >
                        Limpiar
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} sm={listBreaks?.sm} md={listBreaks?.md} lg={listBreaks?.lg} xl={listBreaks?.xl}>
            <InfiniteScroll
              className={id}
              dataLength={showedItems}
              next={fetchHasMore}
              hasMore={hasMore}
              loader={
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <LinearProgress color="secondary" />
                  <Button onClick={fetchHasMore} disabled={localLoading}>
                    Cargar más
                  </Button>
                </Box>
              }
              height={data.length > 0 ? config?.height ?? 400 : localLoading ? 95 : 0}
              endMessage={
                localLoading ? (
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CircularProgress size={60} />
                  </Box>
                ) : (
                  <p style={{ textAlign: 'center' }}>
                    <b>{config?.endMessage ?? 'No hay registros para mostrar'}</b>
                  </p>
                )
              }
            >
              {data.length > 0 && (
                <List dense={true}>
                  {data.map((item, index) => (
                    <Fragment key={index}>
                      {itemComponent({
                        item: item,
                        index: index,
                        selected: selected,
                        handleSelected: disabled ? () => {} : handleSelected,
                        handleAction: disabled ? () => {} : handleAction,
                        disabled,
                        itemValues,
                      })}
                    </Fragment>
                  ))}
                </List>
              )}
            </InfiniteScroll>
          </Grid>
        </Grid>
      </CardContent>

      <CardActions>
        <small>
          Mostrando {showedItems} de {total}
        </small>
      </CardActions>
    </Box>
  );
};

const max = 10;

CustomListMultiple.propTypes = {
  id: PropTypes.string.isRequired,
  API: PropTypes.func.isRequired,
  APIKeys: PropTypes.shape(
    Object.fromEntries(
      Array.from({ length: max }, (_, i) => [
        `input_${i + 1}`,
        i === 0 // Si es el primero, marcarlo como requerido
          ? PropTypes.shape({
              id: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
              filter: PropTypes.string,
              inheritFilterType: PropTypes.string,
            }).isRequired
          : PropTypes.shape({
              id: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
              filter: PropTypes.string,
              inheritFilterType: PropTypes.string,
            }),
      ])
    )
  ).isRequired,
  APIFilter: PropTypes.shape({
    start: PropTypes.array,
    end: PropTypes.array,
  }),
  APISort: PropTypes.shape({
    start: PropTypes.array,
    end: PropTypes.array,
  }),
  pageSize: PropTypes.number,
  label: PropTypes.string, // "Encuentra al usuario",
  labelNote: PropTypes.string, // "Nota: Debe ingresar un nombre",
  initialSearch: PropTypes.bool, // Busca al iniciar el componente
  emptySearch: PropTypes.bool, // Busca al poner el input en ""
  header: PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string,
  }),
  config: PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    endMessage: PropTypes.string,
  }),
  disableFinder: PropTypes.bool,
  breaks: PropTypes.shape({
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
  }),
  buttonsBreaks: PropTypes.shape({
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
  }),
  listBreaks: PropTypes.shape({
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
  }),
  labels: PropTypes.shape(
    Object.fromEntries(Array.from({ length: max }, (_, i) => [`input_${i + 1}`, PropTypes.string]))
  ),
  placeholders: PropTypes.shape(
    Object.fromEntries(Array.from({ length: max }, (_, i) => [`input_${i + 1}`, PropTypes.string]))
  ),
  // Valores iniciales
  initialValues: PropTypes.shape(
    Object.fromEntries(Array.from({ length: max }, (_, i) => [`input_${i + 1}`, PropTypes.string]))
  ),
  // Acepta validar formatos como isTypePhone
  inputValidation: PropTypes.shape(
    Object.fromEntries(Array.from({ length: max }, (_, i) => [`input_${i + 1}`, PropTypes.func]))
  ),
  // Le da un formato como limpiarTelefono
  inputFormat: PropTypes.shape(
    Object.fromEntries(Array.from({ length: max }, (_, i) => [`input_${i + 1}`, PropTypes.func]))
  ),
  lengthValidation: PropTypes.shape(
    Object.fromEntries(
      Array.from({ length: max }, (_, i) => [
        `input_${i + 1}`,
        PropTypes.shape({
          filter: PropTypes.oneOf(['=', '>', '<', '!=', '>=', '<=']),
          value: PropTypes.number,
        }),
      ])
    )
  ),
  clearData: PropTypes.bool, // Limpia los datos al dar en limpiar
  selectFirst: PropTypes.bool, // Selecciona el primer resultado
  disabled: PropTypes.bool,
  disableHeader: PropTypes.bool,
  disableCardType: PropTypes.bool,
  itemComponent: PropTypes.any,
  // Ingresar el texto
  itemValues: PropTypes.shape({
    primary: PropTypes.string,
    secondary: PropTypes.string,
    icon: PropTypes.string,
    disableIcon: PropTypes.bool,
  }),
  setLoading: PropTypes.func,
  setLoadingHasMore: PropTypes.func,
  handleClick: PropTypes.func,
  handleAction: PropTypes.func,
  handleClear: PropTypes.func,
  headerComponent: PropTypes.node,
};

export default CustomListMultiple;

// Ejemplo de uso
/*
<CustomListMultiple
  API={UserService.getUsers}
  APIKeys={{
    input_1: { id: ["usuarios.Nombre", "usuarios.Paterno", "usuarios.Materno"], filter: "LIKE" },
    input_2: { id: "usuarios.Username", filter: "=" },
  }}
  itemComponent={DefaultListItem} // Se puede omitir
  handleClick={handleOption}
  inputValidation={{ input_2: isTypePhone }}
  inputFormat={{ input_2: limpiarTelefono }}
  lengthValidation={{ input_2: { filter: "=", value: 10 } }}
  initialSearch
  emptySearch
  doubleSearch
  clearData
/>
*/
