import { useState, useEffect, useRef } from 'react';

// Material UI
import { Card, Grid, IconButton, Icon, Box, CardContent, Typography, Tooltip, Button } from '@mui/material';

// Componentes
import { showSnackbar } from '@components/Alerts/CustomSnack';
import CustomTable from '@components/Tables/CustomTable';
import Filter from '@components/Aportaciones/Filter';
import Download from '@components/Downloads/Download';

// Servicios
import services from '@services/AportacionesServices';
import { COLUMNS_MIS_CARGOS } from '@data/constants/Aportaciones';

// Middleware
import middleware from '@middlewares/middleware';

// Utils
import { Swal, SwalImage, SwalDelete, SwalConfirm } from '@utils/alerts';
import { isEmptyOrInvalidString } from '@utils/validations';

// Data
import { estatus_cargo } from '@data/constants/Aportaciones';

const MisCargosView = (props) => {
  const { catalogs, isLoadingCat, idUser } = props;

  const canDownload = middleware.checkMenuAction('Exportar');
  const canUpload = middleware.checkMenuAction('Importar');

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const [params, setParams] = useState({ page: 0, pageSize: 10, filtered: [], sorted: [] });

  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    getData(params); // eslint-disable-next-line
  }, [params]);

  const getData = async (params) => {
    const newParams = {
      ...params,
      filtered: [{ id: 'ca.idUsuarioSIAN', filter: '=', value: idUser }, ...(params.filtered || [])],
    };

    try {
      setIsLoading(true);

      const result = await services.getCargos(newParams);
      const { results, response, message } = result;

      if (results) {
        const style = { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: 14 };

        const format = response.data.map((item) => {
          const haveImage = !isEmptyOrInvalidString(item.FileURL);
          const pagado = item.isPagado && item.FechaPago && estatus_cargo.pagado === item.idEstatusCargo;

          return {
            ...item,
            Aportante: <Typography sx={{ maxWidth: 200, ...style }}>{item.Aportante?.trim()}</Typography>,
            FechaCargo: <Typography sx={{ width: 90, fontSize: 14 }}>{item.FechaCargo?.trim()}</Typography>,
            FechaPago: <Typography sx={{ width: 90, fontSize: 14 }}>{item.FechaPago?.trim()}</Typography>,
            LineaCaptura: (
              <Tooltip title={item.LineaCaptura} arrow>
                <Typography sx={{ maxWidth: 120, ...style }}>{item.LineaCaptura}</Typography>
              </Tooltip>
            ),
            Comprobante: (
              <FileUploader
                item={{ ...item, haveImage, pagado }}
                handleFileChange={(e) => handleFileChange(e, item.id)}
              />
            ),

            actions: (
              <Box sx={{ display: 'flex', justifyContent: 'end', gap: 1 }}>
                {canUpload && haveImage && !pagado && (
                  <Tooltip title="Eliminar comprobante" disableInteractive arrow>
                    <IconButton aria-label="delete" color="error" onClick={() => deleteConfirmation(item)}>
                      <Icon>delete</Icon>
                    </IconButton>
                  </Tooltip>
                )}
                {canDownload && (
                  <Tooltip title="Descargar orden de pago" disableInteractive arrow>
                    <IconButton aria-label="export" color="primary" onClick={() => handleDownload(item)}>
                      <Icon>download</Icon>
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            ),
          };
        });

        setData(format);
        setTotal(response.total);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = async ({ id }) => {
    try {
      setIsDownloading(true);

      const result = await services.downloadCargos({ id });
      const { results, message } = result;
      if (!results) {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'success' });
    } finally {
      setIsDownloading(false);
    }
  };

  const deleteConfirmation = async ({ Nombre, id }) => {
    SwalDelete({
      title: 'Eliminar comprobante',
      text: `¿Estas seguro de querer eliminar el comprobante de "${Nombre?.trim()}"?`,
      buttonText: 'Eliminar comprobante',
      onDelete: () => deleteComprobanteCargo({ id }),
    });
  };

  const deleteComprobanteCargo = async ({ id }) => {
    try {
      setIsLoading(true);

      const result = await services.deleteComprobanteCargo({ id });
      const { results, message } = result;

      if (results) {
        getData(params);
        showSnackbar({ message, color: 'success' });
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
      setIsLoading(false);
    }
  };

  const uploadConfirmation = async ({ id, file }) => {
    SwalConfirm({
      title: 'Subir comprobante',
      text: `¿Estas seguro de querer subir esa imagen como comprobante?`,
      buttonText: 'Subir comprobante',
      OnConfirm: () => uploadComprobanteCargo({ id, file }),
    });
  };

  const uploadComprobanteCargo = async ({ id, file }) => {
    try {
      setIsLoading(true);

      const result = await services.uploadComprobanteCargo({ id, file });
      const { results, message } = result;

      if (results) {
        getData(params);
        showSnackbar({ message, color: 'success' });
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
      setIsLoading(false);
    }
  };

  const handleFileChange = (e, id) => {
    const uploadedFile = e.target.files[0];
    e.target.value = null;

    if (uploadedFile) {
      const reader = new FileReader();
      reader.onloadend = () => uploadConfirmation({ id, file: reader.result });
      reader.readAsDataURL(uploadedFile);
    }
  };

  const handlePagination = ({ page, pageSize }) => setParams((prev) => ({ ...prev, page, pageSize }));

  const handleSort = ({ orderBy, order }) => {
    const filters = {
      Nombre: 'cac.Nombre',
      Quincena: 'cac.Quincena',
      MontoCargo: 'cac.MontoCargo',
      FechaCargo: 'cac.FechaCargo',
      LineaCaptura: 'cac.LineaCaptura',
      FechaPago: 'cac.FechaPago',
      Estatus: 'caec.id',
    };

    setParams((prev) => ({ ...prev, sorted: [{ id: filters[orderBy], value: order }] }));
  };

  const handleFilter = (values) => setParams((prev) => ({ ...prev, filtered: values }));

  return (
    <Card>
      {/* Descargar archivo exportado */}
      {isDownloading && (
        <Box marginBottom={2}>
          <Download format="pdf" isDownload={isDownloading} />
        </Box>
      )}

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Filter
              handleFilter={handleFilter}
              catalogs={catalogs}
              isLoadingCat={isLoadingCat}
              flags={{ cat_estatus: true, cat_comprobante: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTable
              rows={data}
              columns={COLUMNS_MIS_CARGOS(canDownload || canUpload)}
              total={total}
              page={params.page}
              pageSize={params.pageSize}
              isLoading={isLoading}
              handlePagination={handlePagination}
              handleSort={handleSort}
              disableCardType
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const FileUploader = ({ item = {}, handleFileChange }) => {
  const { haveImage, pagado, FileURL, Nombre } = item;

  const fileInputRef = useRef(null);
  const handleClick = () => fileInputRef.current?.click();

  return (
    <>
      <Button
        fullWidth
        color={pagado ? 'success' : 'primary'}
        variant={haveImage ? 'outlined' : 'contained'}
        onClick={haveImage ? () => SwalImage({ image: FileURL, title: Nombre }) : handleClick}
        sx={{ whiteSpace: 'nowrap' }}
        disabled={pagado && !haveImage ? true : false}
      >
        {haveImage ? 'Ver comprobante' : 'Subir comprobante'}
      </Button>
      {!haveImage && (
        <input
          type="file"
          ref={fileInputRef}
          accept=".png,.jpg,.jpeg"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      )}
    </>
  );
};

export default MisCargosView;
