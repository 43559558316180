import { ApiExec } from '@utils/ApiExec';

class NotificacionesServices {
  getMyNotifications = (params) => ApiExec(params, 'notificaciones/get-my-notifications', 'POST');

  updateMyNotificationsAsRead = (params) =>
    ApiExec(params, 'notificaciones/update-my-notifications-as-read', 'POST');
}

const service = new NotificacionesServices();
export default service;
