import { estatus_cargo, estatus_pagado } from '@data/constants/Aportaciones';

const AportantesInterface = {
  idUsuarioSIAN: null,

  RNM: '',
  FechaAltaRNM: '',

  CURP: '',
  INE: '',
  SeccionVota: '',

  Nombre: '',
  Paterno: '',
  Materno: '',

  idMunicipio: '',
  Colonia: '',
  Calle: '',
  NumExt: '',
  NumInt: '',
  CP: '',

  Correo: '',
  Celular: '',
  Facebook: '',
  X: '',
  TikTok: '',
  Instagram: '',
};

const EditAportantesInterface = {
  id: '',
  ...AportantesInterface,
};

const PerfilInterface = {
  PerfilAportador: '',
  Porcentaje: '',
};

const EditPerfilInterface = {
  id: '',
  ...PerfilInterface,
};

const PercepcionesInterface = {
  idAportante: '',
  idPerfil: '',
  Puesto: '',
  Dependencia: '',
  MontoNeto: '',
};

const EditPercepcionesInterface = {
  id: '',
  ...PercepcionesInterface,
};

const CargosInterface = {
  idAportante: '',
  Nombre: '',
  Descripcion: '',
  PorcentajeCargo: null,
  Quincena: null,
  MontoCargo: '',
  FechaCargo: new Date(),
  LineaCaptura: null,
  idEstatusCargo: estatus_cargo.pendiente,
  isPagado: estatus_pagado.no,
  FechaPago: '',
};

const EditCargosInterface = {
  id: '',
  ...CargosInterface,
};

const JoinCargosInterface = {
  idAportante: '',
  Nombre: '',
  Descripcion: '',
  idsCargos: [],
};

const PagosInterface = {
  idAportante: '',
  idCargo: '',
  MontoPagado: '',
  FechaPago: new Date(),
  idTipoPago: '',
  Banco: 'BBVA',
};

const EditPagosInterface = {
  id: '',
  ...PagosInterface,
};

export { AportantesInterface, EditAportantesInterface };
export { PerfilInterface, EditPerfilInterface };
export { PercepcionesInterface, EditPercepcionesInterface };
export { CargosInterface, EditCargosInterface, JoinCargosInterface };
export { PagosInterface, EditPagosInterface };
