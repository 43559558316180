import { useState, useEffect, Fragment } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// Material UI
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Breadcrumbs,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ListItemIcon,
  Icon,
  Box,
  Badge,
  Divider,
  Tooltip,
} from '@mui/material';
import {
  PowerSettingsNew,
  Notifications,
  Check,
  Circle,
  ChatBubble,
  NotificationsOff,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

// Componentes
import CustomLoading from '@components/LinearProgress/CustomLoading';
import { showSnackbar } from '@components/Alerts/CustomSnack';
import ProfilePhoto from '@components/Users/ProfilePhoto';

// Utilidades
import { getVars, setVars } from '@utils/global';
import { Swal } from '@utils/alerts';
import { isEmptyOrNullObject } from '@utils/validations';

// Servicios
import AuthServices from '@services/AuthServices';
import NotificacionesServices from '@services/NotificacionesServices';

// Estilo del Navbar
const AppBars = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const timeout = 350;

const AdminNavbar = (props) => {
  const { handleToggle, title, setRefresh, refresh } = props;
  const token = getVars('Token');
  const navigateMenu = getVars('sideBar');
  const navigate = useNavigate();

  const activeMenus = { account: true, notification: true, status: false };

  // Menu de Estatus
  const [anchorStatus, setAnchorStatus] = useState(null);
  const handleCloseStatus = () => setAnchorStatus(null);
  const handleClickStatus = (event) => setAnchorStatus(event.currentTarget);

  // Menu de Notificaciones
  const [anchorNotif, setAnchorNotif] = useState(null);
  const handleCloseNotif = () => setAnchorNotif(null);
  const handleClickNotif = (event) => setAnchorNotif(event.currentTarget);

  // Menu de Perfil
  const [anchorPerfil, setAnchorPerfil] = useState(null);
  const handleClosePerfil = () => setAnchorPerfil(null);
  const handleClickPerfil = (event) => setAnchorPerfil(event.currentTarget);

  const [isOpen, setIsOpen] = useState(false);

  const iconSX = { fontSize: '12px', marginRight: '5px' };

  // Datos del menu de Estatus
  const statusMenu = [
    {
      title: 'Conectado',
      icon: <Circle style={{ color: '#00A656', ...iconSX }} />,
      action: (e) => {
        setTimeout(() => {
          handleCloseStatus();
        }, timeout);
      },
    },
    {
      title: 'Ausente',
      icon: <Circle style={{ color: '#ED8F1C', ...iconSX }} />,
      action: (e) => {
        setTimeout(() => {
          handleCloseStatus();
        }, timeout);
      },
    },
    {
      title: 'Invisible',
      icon: <Circle style={{ color: '#68737D', ...iconSX }} />,
      action: (e) => {
        setTimeout(() => {
          handleCloseStatus();
        }, timeout);
      },
    },
  ];

  // Datos del menu de Perfil
  const perfilMenu = [
    {
      special: true,
      title: 'Mi Perfil',
      icon: <ProfilePhoto idFotoPerfil={token?.user?.idFotoPerfil} size={24} icon />,
      action: () => {
        navigate('/mi-perfil');
        setTimeout(() => {
          handleClosePerfil();
        }, timeout);
      },
    },
    { title: 'Divider' },
    {
      title: 'Recargar perfil',
      icon: <Icon>sync</Icon>,
      action: async () => {
        try {
          setRefresh(false);
          setIsOpen(true);

          const result = await AuthServices.getRefreshLogin({ type_login: 'web' });
          const { results, response, message } = await result;

          if (results) {
            const { notifications = {}, ...others } = response;

            setVars('Token', others);
            setVars('notifications', notifications);
            showSnackbar({ message: 'Perfil actualizado con éxito', color: 'success' });
          } else throw new Error(message);
        } catch (e) {
          Swal.fire({ title: e.message, icon: 'warning' });
        } finally {
          setIsOpen(false);
          setRefresh(true);
          handleClosePerfil();
        }
      },
    },
    { title: 'Divider' },
    {
      title: 'Cerrar sesión',
      icon: <PowerSettingsNew />,
      action: async () => {
        sessionStorage.clear();
        navigate('/login');
      },
    },
  ];

  return (
    <AppBars position="fixed" className="gto-blue">
      <CustomLoading isOpen={isOpen} message="Cargando datos..." />

      <Toolbar>
        {/* Abre y cierra el Sidebar */}
        <IconButton
          id="sidebarButton"
          size="small"
          variant="outlined"
          onClick={(e) => handleToggle()}
          sx={{ marginRight: '40px', marginLeft: '0px' }}
        >
          <Icon sx={{ color: 'white' }}>menu</Icon>
        </IconButton>

        {/* Navegación con Breadcrumbs (desktop) */}
        <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ flexGrow: 1, color: 'white' }}>
            <Typography
              color="whitesmoke"
              variant="h6"
              component={NavLink}
              to="/"
              onClick={() => setVars('sideBar', '')}
              sx={{ '&:hover': { color: '#A7B0B8' } }}
            >
              {title}
            </Typography>
            {navigateMenu.Menu ? (
              <Typography sx={{ display: { xs: 'none', sm: 'flex' } }} color="mintcream">
                {navigateMenu.Menu}
              </Typography>
            ) : (
              ''
            )}
          </Breadcrumbs>
        </Box>

        {/* Navegación (mobile) */}
        <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
          <Typography
            color="whitesmoke"
            variant="h6"
            component={NavLink}
            to="/"
            onClick={() => setVars('sideBar', '')}
            sx={{ '&:hover': { color: '#A7B0B8' } }}
          >
            {title}
          </Typography>
        </Box>

        {/* Separación */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Botones del Navbar */}
        <Box sx={{ display: { xs: 'flex' } }}>
          {activeMenus.status && (
            <StatusMenu
              data={statusMenu}
              title="Estatus"
              token={token}
              anchorEl={anchorStatus}
              handleClose={handleCloseStatus}
              handleClick={handleClickStatus}
            />
          )}

          {activeMenus.notification && (
            <NotificationMenu
              refresh={refresh}
              title="Notificaciones"
              anchorEl={anchorNotif}
              handleClose={handleCloseNotif}
              handleClick={handleClickNotif}
            />
          )}

          {activeMenus.account && (
            <AccountMenu
              data={perfilMenu}
              title="Perfil"
              token={token}
              anchorEl={anchorPerfil}
              handleClose={handleClosePerfil}
              handleClick={handleClickPerfil}
            />
          )}
        </Box>
      </Toolbar>
    </AppBars>
  );
};

const StatusMenu = (props) => {
  const { data, title, /* token, */ anchorEl, handleClose, handleClick } = props;

  const open = Boolean(anchorEl);

  const [selected, setSelected] = useState(0);

  const color = selected === 2 ? '#68737D' : selected ? '#ED8F1C' : '#00A656';

  const handleListItemClick = (index) => {
    setSelected(index);
  };

  const handleListKeyDown = (e) => {
    if (e.key === 'Tab' || e.key === 'Escape') {
      e.preventDefault();
      handleClose();
    }
  };

  return (
    <Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title={open ? '' : title} placement="bottom" disableInteractive arrow>
          <IconButton onClick={handleClick} size="large" aria-label={`show status`} color="inherit">
            <ChatBubble sx={{ color: color }} />
          </IconButton>
        </Tooltip>
      </Box>

      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'rigth top',
            }}
          >
            <Paper style={{ marginTop: '8px' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <div
                  style={{
                    position: 'relative',
                    overflow: 'visible',
                  }}
                >
                  {/* Flecha hacia arriba */}
                  <div
                    style={{
                      position: 'absolute',
                      top: '-8px', // Posición vertical
                      right: '15px', // Posición horizontal
                      width: '0',
                      height: '0',
                      borderLeft: '10px solid transparent',
                      borderRight: '10px solid transparent',
                      borderBottom: '10px solid white', // Color de fondo del Popper
                    }}
                  ></div>
                  {/* Items */}
                  <MenuList
                    autoFocusItem={open}
                    id={`Navbar${title}Menu`}
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {data.map((item, index) =>
                      item.title === 'Divider' ? (
                        <Divider key={index} />
                      ) : (
                        <MenuItem
                          key={index}
                          onClick={() => {
                            handleListItemClick(index);
                            item.action(index);
                          }}
                          selected={selected === index}
                        >
                          {selected === index ? (
                            <ListItemIcon>
                              <Check style={{ color: '#3984BF' }} />
                            </ListItemIcon>
                          ) : (
                            <ListItemIcon />
                          )}
                          {item.icon} {item.title}
                        </MenuItem>
                      )
                    )}
                  </MenuList>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

const NotificationMenu = (props) => {
  const { title, anchorEl, handleClose, handleClick, refresh } = props;

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [newNotifications, setNewNotifications] = useState(0);
  const [filtered, setFiltered] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (refresh) {
      const notifications = getVars('notifications', {});
      setData(notifications.data || []);
      setNewNotifications(notifications.newNotifications || 0);
      setTotal(notifications.total || 0);
    }
    // eslint-disable-next-line
  }, [refresh]);

  const open = Boolean(anchorEl);

  const maxWidth = '250px';
  const maxHeight = '250px';

  const getMyNotifications = async (isMore = false, filter = []) => {
    try {
      setIsLoading(true);

      if (!isMore) setFiltered(filter);

      const ids = data.map((item) => item.id);

      const result = await NotificacionesServices.getMyNotifications({
        filtered: isMore ? [{ id: 'n.id', filter: 'NOT IN', value: ids }, ...filtered] : filter,
      });
      const { results, response, message } = await result;

      if (results) {
        const newData = isMore ? [...data, ...(response.data || [])] : response.data || [];
        response.data = newData;

        setVars('notifications', response);
        setData(response.data || []);
        setNewNotifications(response.newNotifications || 0);
        setTotal(response.total || 0);
      } else throw new Error(message);
    } catch ({ message }) {
      if (!isMore && filter.length) {
        setData([]);
        setNewNotifications(0);
      }

      showSnackbar({ message });
    } finally {
      setIsLoading(false);
    }
  };

  const updateMyNotificationsAsRead = async ({ id = 0, type = 'all' }) => {
    try {
      setIsLoading(true);

      let lastID = id;

      if (type === 'all' && data?.length) {
        const ids = data?.map((item) => item.id) || [];
        lastID = Math.max(...ids);
      }

      if (lastID) {
        const result = await NotificacionesServices.updateMyNotificationsAsRead({ lastID, type });
        const { results, response, message } = await result;

        if (results && type === 'all') {
          setVars('notifications', response);
          setData(response.data || []);
          setNewNotifications(response.newNotifications || 0);
          setTotal(response.total || 0);
        } else if (!results) throw new Error(message);
      }
    } catch ({ message }) {
      showSnackbar({ message });
    } finally {
      setIsLoading(false);
    }
  };

  const handleListKeyDown = (e) => {
    if (e.key === 'Tab' || e.key === 'Escape') {
      e.preventDefault();
      handleClose();
    }
  };

  const handleAction = ({ id, isRead, action }) => {
    setTimeout(() => handleClose(), timeout);

    if (!isRead) {
      let notifications = getVars('notifications', {});

      const newData = notifications.data.map((item) => (item.id === id ? { ...item, isRead: true } : item));

      notifications.data = newData;
      notifications.newNotifications = notifications.newNotifications - 1;

      setVars('notifications', notifications);
      setData(notifications.data || []);
      setNewNotifications(notifications.newNotifications || 0);

      updateMyNotificationsAsRead({ id, type: 'one' });
    }

    if (!isEmptyOrNullObject(action)) {
      switch (action.type) {
        case 'navigate':
          navigate(action.route);
          break;
        default:
          break;
      }
    }
  };

  return (
    <Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title={open ? '' : title} placement="bottom" disableInteractive arrow>
          <IconButton
            onClick={handleClick}
            size="large"
            aria-label={`show ${newNotifications} new notifications`}
            color="inherit"
          >
            <Badge badgeContent={newNotifications} color="error">
              <Notifications />
            </Badge>
          </IconButton>
        </Tooltip>
      </Box>

      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'rigth top' }}>
            <Paper style={{ marginTop: '8px' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <div style={{ position: 'relative', overflow: 'visible' }}>
                  {/* Flecha hacia arriba */}
                  <div
                    style={{
                      position: 'absolute',
                      top: '-8px', // Posición vertical
                      right: '15px', // Posición horizontal
                      width: '0',
                      height: '0',
                      borderLeft: '10px solid transparent',
                      borderRight: '10px solid transparent',
                      borderBottom: '10px solid white', // Color de fondo del Popper
                    }}
                  />
                  {/* Items */}
                  <MenuList
                    autoFocusItem={open}
                    id={`Navbar${title}Menu`}
                    aria-labelledby="composition-button"
                    sx={{
                      maxHeight,
                      overflowY: 'auto',
                      '&::-webkit-scrollbar': { width: '4px' },
                      '&::-webkit-scrollbar-track': {
                        backgroundColor: '#f0f0f0',
                        mt: 6,
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        borderRadius: '6px',
                      },
                      '&::-webkit-scrollbar-thumb:hover': { backgroundColor: 'rgba(0, 0, 0, 0.4)' },
                    }}
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      sx={{
                        p: 0,
                        pb: 0.5,
                        pr: 1,
                        justifyContent: 'space-between',
                        ':hover': { bgcolor: '#ffff', cursor: 'auto' },
                      }}
                      disableRipple
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          pl: 2,
                          pr: 1,
                          fontWeight: '600',
                          maxWidth,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        Notificaciones
                      </Typography>

                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <Tooltip title="Ver no leidas" placement="bottom" disableInteractive arrow>
                          <IconButton
                            onClick={() =>
                              getMyNotifications(false, [{ id: 'n.isLeida', filter: '=', value: 0 }])
                            }
                            size="small"
                            aria-label="Ver notificaciones no leidas"
                            color="inherit"
                            sx={{ ':hover': { color: 'green' } }}
                            disabled={isLoading}
                          >
                            <Icon>remove_done</Icon>
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Marcar como leidas" placement="bottom" disableInteractive arrow>
                          <IconButton
                            onClick={() => updateMyNotificationsAsRead({})}
                            size="small"
                            aria-label="Marcar notificaciones como leidas"
                            color="inherit"
                            sx={{ ':hover': { color: 'green' } }}
                            disabled={isLoading}
                          >
                            <Icon>done_all</Icon>
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Recargar" placement="bottom" disableInteractive arrow>
                          <IconButton
                            onClick={() => getMyNotifications(false)}
                            size="small"
                            aria-label="Recargar notificaciones"
                            color="inherit"
                            sx={{ ':hover': { color: '#1976d2' } }}
                            disabled={isLoading}
                          >
                            <Icon
                              sx={{
                                animation: isLoading ? `rotate 0.8s linear infinite` : '',
                                color: isLoading ? '#1976d2' : 'inherit',
                              }}
                            >
                              sync
                            </Icon>
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </MenuItem>

                    {data.length ? (
                      data.map((item, index) => (
                        <Box key={index}>
                          <Divider style={{ margin: 0 }} />

                          <MenuItem
                            onClick={() => handleAction(item)}
                            sx={{
                              width: maxWidth,
                              backgroundColor: item.isRead ? '' : '#ede8ff',
                              ':hover': { backgroundColor: item.isRead ? '' : '#dbd1ff' },
                            }}
                            disabled={isLoading}
                          >
                            <Box>
                              <Typography
                                variant="body2"
                                sx={{
                                  maxWidth: `calc(${maxWidth} - 30px)`,
                                  fontWeight: '600',
                                  overflow: 'hidden',
                                  whiteSpace: 'wrap',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {item.title}
                              </Typography>

                              <Typography
                                variant="body2"
                                sx={{
                                  maxWidth: `calc(${maxWidth} - 30px)`,
                                  fontSize: 'smaller',
                                  overflow: 'hidden',
                                  whiteSpace: 'wrap',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {item.body}
                              </Typography>
                            </Box>
                          </MenuItem>
                        </Box>
                      ))
                    ) : (
                      <Box>
                        <Divider style={{ margin: 0 }} />

                        <MenuItem
                          disabled
                          sx={{
                            width: maxWidth,
                            height: `calc(${maxHeight} - 100px)`,
                            justifyContent: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <Typography>
                            <NotificationsOff sx={{ fontSize: 70 }} />
                          </Typography>

                          <Typography
                            variant="body2"
                            sx={{
                              maxWidth: `calc(${maxWidth} - 30px)`,
                              fontWeight: '600',
                              overflow: 'hidden',
                              whiteSpace: 'wrap',
                              textOverflow: 'ellipsis',
                              textAlign: 'center',
                            }}
                          >
                            No tienes notificaciones
                          </Typography>
                        </MenuItem>
                      </Box>
                    )}

                    {data?.length > 0 && data?.length < total && (
                      <Box>
                        <Divider sx={{ my: 0.5 }} />

                        <MenuItem
                          variant="outlined"
                          onClick={() => getMyNotifications(true)}
                          sx={{ justifyContent: 'center', mx: 2, borderRadius: 2 }}
                        >
                          <Typography variant="body2">Cargar más</Typography>
                        </MenuItem>
                      </Box>
                    )}
                  </MenuList>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

const AccountMenu = (props) => {
  const { data, title, token, anchorEl, handleClose, handleClick } = props;

  const open = Boolean(anchorEl);

  const handleListKeyDown = (e) => {
    if (e.key === 'Tab' || e.key === 'Escape') {
      e.preventDefault();
      handleClose();
    }
  };

  const maxWidth = '120px';

  return (
    <Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title={open ? null : title} placement="bottom" disableInteractive arrow>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <ProfilePhoto idFotoPerfil={token?.user?.idFotoPerfil} size={32} />
          </IconButton>
        </Tooltip>
      </Box>

      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'rigth top',
            }}
          >
            <Paper style={{ marginTop: '8px' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <div
                  style={{
                    position: 'relative',
                    overflow: 'visible',
                  }}
                >
                  {/* Flecha hacia arriba */}
                  <div
                    style={{
                      position: 'absolute',
                      top: '-8px', // Posición vertical
                      right: '16px', // Posición horizontal
                      width: '0',
                      height: '0',
                      borderLeft: '10px solid transparent',
                      borderRight: '10px solid transparent',
                      borderBottom: '10px solid white', // Color de fondo del Popper
                    }}
                  />
                  {/* Items */}
                  <MenuList
                    autoFocusItem={open}
                    id={`Navbar${title}Menu`}
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {data.map((item, index) =>
                      item.title === 'Divider' ? (
                        <Divider key={index} style={{ margin: '0' }} />
                      ) : (
                        <MenuItem key={index} onClick={() => item.action()}>
                          <ListItemIcon>{item.icon}</ListItemIcon>

                          {item.special ? (
                            <Box>
                              <Typography
                                variant="body1"
                                sx={{
                                  fontWeight: 'bold',
                                  maxWidth, // Ancho máximo
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {token.user.Nombre}
                              </Typography>
                              <Typography variant="body2" sx={{ fontSize: 'smaller' }}>
                                Ver perfil
                              </Typography>
                            </Box>
                          ) : (
                            <Typography
                              variant="body1"
                              sx={{
                                maxWidth, // Ancho máximo
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {item.title}
                            </Typography>
                          )}
                        </MenuItem>
                      )
                    )}
                  </MenuList>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

AdminNavbar.propTypes = {
  handleToggle: PropTypes.func,
};

export default AdminNavbar;
