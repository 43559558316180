import { PrivilegedRoute } from '@middlewares';

import Aportantes from '@pages/Aportaciones/Aportantes';
import Perfil from '@pages/Aportaciones/Perfil';
import Percepciones from '@pages/Aportaciones/Percepciones';
import Cargos from '@pages/Aportaciones/Cargos';
import Pagos from '@pages/Aportaciones/Pagos';
import MisCargos from '@pages/Aportaciones/MisCargos';

const route = [
  {
    path: '/aportaciones/aportantes',
    element: (
      <PrivilegedRoute>
        <Aportantes />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/aportaciones/perfil',
    element: (
      <PrivilegedRoute>
        <Perfil />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/aportaciones/percepciones',
    element: (
      <PrivilegedRoute>
        <Percepciones />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/aportaciones/cargos',
    element: (
      <PrivilegedRoute>
        <Cargos />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/aportaciones/mis_cargos',
    element: (
      <PrivilegedRoute>
        <MisCargos />
      </PrivilegedRoute>
    ),
  },
  {
    path: '/aportaciones/pagos',
    element: (
      <PrivilegedRoute>
        <Pagos />
      </PrivilegedRoute>
    ),
  },
];

export default route;
