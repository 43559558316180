import { ApiExec, ApiExecDecrypted, downloadFile } from '@utils/ApiExec';

class AportacionesServices {
  getAportantes = (data) => ApiExec(data, 'cdeg/aportaciones/get-aportantes', 'POST');
  createAportante = (data) => ApiExec(data, 'cdeg/aportaciones/create-aportante', 'POST');
  updateAportante = (data) => ApiExec(data, 'cdeg/aportaciones/update-aportante', 'POST');
  deleteAportante = (data) => ApiExec(data, 'cdeg/aportaciones/delete-aportante', 'POST');
  getAportantesWithCargos = (data) => ApiExec(data, 'cdeg/aportaciones/get-aportantes-with-cargos', 'POST');

  getPerfiles = (data) => ApiExec(data, 'cdeg/aportaciones/get-perfiles', 'POST');
  createPerfil = (data) => ApiExec(data, 'cdeg/aportaciones/create-perfil', 'POST');
  updatePerfil = (data) => ApiExec(data, 'cdeg/aportaciones/update-perfil', 'POST');
  deletePerfil = (data) => ApiExec(data, 'cdeg/aportaciones/delete-perfil', 'POST');

  getPercepciones = (data) => ApiExec(data, 'cdeg/aportaciones/get-percepciones', 'POST');
  createPercepcion = (data) => ApiExec(data, 'cdeg/aportaciones/create-percepcion', 'POST');
  updatePercepcion = (data) => ApiExec(data, 'cdeg/aportaciones/update-percepcion', 'POST');
  deletePercepcion = (data) => ApiExec(data, 'cdeg/aportaciones/delete-percepcion', 'POST');

  getCargos = (data) => ApiExec(data, 'cdeg/aportaciones/get-cargos', 'POST');
  createCargo = (data) => ApiExec(data, 'cdeg/aportaciones/create-cargo', 'POST');
  updateCargo = (data) => ApiExec(data, 'cdeg/aportaciones/update-cargo', 'POST');
  deleteCargo = (data) => ApiExec(data, 'cdeg/aportaciones/delete-cargo', 'POST');
  joinCargos = (data) => ApiExec(data, 'cdeg/aportaciones/join-cargos', 'POST');
  downloadCargos = (data) => downloadFile({ api: 'cdeg/aportaciones/download-cargos', method: 'POST', data });

  uploadComprobanteCargo = (data) =>
    ApiExecDecrypted(data, 'cdeg/aportaciones/upload-comprobante-cargo', 'POST', false);
  deleteComprobanteCargo = (data) => ApiExec(data, 'cdeg/aportaciones/delete-comprobante-cargo', 'POST');
  denyComprobanteCargo = (data) => ApiExec(data, 'cdeg/aportaciones/deny-comprobante-cargo', 'POST');

  getPagos = (data) => ApiExec(data, 'cdeg/aportaciones/get-pagos', 'POST');
  createPago = (data) => ApiExec(data, 'cdeg/aportaciones/create-pago', 'POST');
  updatePago = (data) => ApiExec(data, 'cdeg/aportaciones/update-pago', 'POST');
  deletePago = (data) => ApiExec(data, 'cdeg/aportaciones/delete-pago', 'POST');

  createBanco = (data) => ApiExec(data, 'cdeg/aportaciones/create-banco', 'POST');
}

const service = new AportacionesServices();
export default service;
