import { useState, useEffect } from 'react';
import { parseISO } from 'date-fns';

// Material UI
import { Card, Grid, IconButton, Icon, Box, CardContent, Typography, Tooltip, Button } from '@mui/material';

// Componentes
import CustomTable from '@components/Tables/CustomTable';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import Form from '@components/Aportaciones/CargosForm';
import Filter from '@components/Aportaciones/Filter';
import PagosForm from '@components/Aportaciones/PagosForm';
import CustomModal from '@components/Modal/CustomModal';
import Download from '@components/Downloads/Download';

// Servicios
import services from '@services/AportacionesServices';
import { COLUMNS_CARGOS } from '@data/constants/Aportaciones';

// Middleware
import middleware from '@middlewares/middleware';

// Utils
import { Swal, SwalDelete, SwalImage } from '@utils/alerts';
import { isEmptyOrInvalidString } from '@utils/validations';

// Data
import { estatus_cargo } from '@data/constants/Aportaciones';

const CargosView = (props) => {
  const { catalogs, isLoadingCat, reloadCatalogs = () => {} } = props;

  const canEdit = middleware.checkMenuAction('Editar');
  const canDelete = middleware.checkMenuAction('Eliminar');
  const canDownload = middleware.checkMenuAction('Exportar');

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const [open, setOpen] = useState(false);
  const [currentData, setCurrentData] = useState({});

  const [params, setParams] = useState({ page: 0, pageSize: 10, filtered: [], sorted: [] });

  const [isPagosForm, setIsPagosForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [message, setMessage] = useState('Eliminado Cargo...');
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    getData(params); // eslint-disable-next-line
  }, [params]);

  const getData = async (params) => {
    try {
      function formatNumber(value) {
        if (!value) return '';
        const number = parseFloat(value);
        if (isNaN(number)) return value;
        if (!number) return '';
        if (number % 1 === 0) return number.toFixed(0);
        return parseFloat(number.toFixed(2)).toString();
      }

      setIsLoading(true);

      const result = await services.getCargos(params);
      const { results, response, message } = result;

      if (results) {
        const style = { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: 14 };

        const format = response.data.map((item) => {
          const haveImage = !isEmptyOrInvalidString(item.FileURL);
          const noPagado =
            !item.isPagado &&
            !item.FechaPago &&
            ![estatus_cargo.pagado, estatus_cargo.sustituido].includes(item.idEstatusCargo);
          return {
            ...item,
            Aportante: <Typography sx={{ maxWidth: 200, ...style }}>{item.Aportante?.trim()}</Typography>,
            FechaCargo: <Typography sx={{ width: 90, fontSize: 14 }}>{item.FechaCargo?.trim()}</Typography>,
            FechaPago: <Typography sx={{ width: 90, fontSize: 14 }}>{item.FechaPago?.trim()}</Typography>,
            LineaCaptura: (
              <Tooltip title={item.LineaCaptura} arrow>
                <Typography sx={{ maxWidth: 120, ...style }}>{item.LineaCaptura}</Typography>
              </Tooltip>
            ),
            Comprobante: (
              <Button
                fullWidth
                color={noPagado ? 'primary' : 'success'}
                variant={noPagado ? 'contained' : 'outlined'}
                onClick={() => {
                  if (haveImage) {
                    if (noPagado) {
                      handleEdit(
                        {
                          idCargo: item.id,
                          idAportante: item.idAportante,
                          Aportante: item.Aportante,
                          Celular: item.Celular,
                          Banco: 'BBVA',
                          FechaPago: new Date(),
                          MontoPagado: item.MontoCargo ? formatNumber(item.MontoCargo) : '',
                          idTipoPago: '',
                          FileURL: item.FileURL,
                        },
                        true
                      );
                    } else SwalImage({ image: item.FileURL, title: item.Nombre });
                  }
                }}
                sx={{ whiteSpace: 'nowrap' }}
                disabled={!haveImage}
              >
                {haveImage ? (noPagado ? 'Validar comprobante' : 'Ver comprobante') : 'Sin comprobante'}
              </Button>
            ),

            actions: (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
                {canDownload && (
                  <IconButton
                    aria-label="export"
                    color="primary"
                    onClick={async () => {
                      await handleDownload(item);
                    }}
                  >
                    <Icon>download</Icon>
                  </IconButton>
                )}
                {canEdit && noPagado && (
                  <IconButton
                    aria-label="edit"
                    color="success"
                    onClick={() =>
                      handleEdit({
                        ...item,
                        FechaCargo: item.FechaCargo ? parseISO(item.FechaCargo) : '',
                        FechaPago: item.FechaPago ? parseISO(item.FechaPago) : '',
                        MontoCargo: item.MontoCargo ? formatNumber(item.MontoCargo) : '',
                        Quincena: item.Quincena ? formatNumber(item.Quincena) : '',
                      })
                    }
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                )}
                {canDelete && noPagado && (
                  <IconButton aria-label="delete" color="error" onClick={() => deleteConfirmation(item)}>
                    <Icon>delete</Icon>
                  </IconButton>
                )}
              </Box>
            ),
          };
        });

        setData(format);
        setTotal(response.total);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => setOpen(false);

  const handleEdit = (values, isPagos = false) => {
    setIsPagosForm(isPagos);
    setOpen(true);
    setCurrentData(values);
  };

  const handleIsEdited = () => setParams((prev) => ({ ...prev, page: 0 }));

  const handleIsReset = () => {
    setIsPagosForm(false);
    setOpen(false);
    setCurrentData({});
    getData(params);
  };

  const denyConfirmation = async ({ Aportante, idCargo }) => {
    SwalDelete({
      title: 'Rechazar comprobante',
      text: `¿Estas seguro de querer rechazar el comprobante de ${Aportante}?`,
      buttonText: 'Rechazar comprobante',
      onDelete: () => handleDeny({ id: idCargo }),
    });
  };

  const handleDeny = async ({ id }) => {
    try {
      setIsDeleting(true);
      setMessage('Rechazando Comprobante...');

      const result = await services.denyComprobanteCargo({ id });
      const { results, message } = result;

      if (results) {
        Swal.fire({ title: message, icon: 'success' });
        handleIsReset();
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsDeleting(false);
    }
  };

  const deleteConfirmation = async ({ Aportante, id, LineaCaptura }) => {
    SwalDelete({
      title: 'Eliminar Cargo',
      text: `¿Estas seguro de querer eliminar el cargo de ${Aportante}?`,
      buttonText: 'Eliminar cargo',
      onDelete: () => handleDelete({ id, LineaCaptura }),
    });
  };

  const handleDelete = async ({ id, LineaCaptura }) => {
    try {
      setIsDeleting(true);
      setMessage('Eliminado Cargo...');

      const result = await services.deleteCargo({ id, LineaCaptura });
      const { results, message } = result;

      if (results) {
        Swal.fire({ title: message, icon: 'success' });
        handleClose();
        getData(params);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDownload = async ({ id }) => {
    try {
      setIsDownloading(true);

      const result = await services.downloadCargos({ id });
      const { results, message } = result;
      if (!results) {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({ title: error.message, icon: 'success' });
    } finally {
      setIsDownloading(false);
    }
  };

  const handlePagination = ({ page, pageSize }) => setParams((prev) => ({ ...prev, page, pageSize }));

  const handleSort = ({ orderBy, order }) => {
    const filters = {
      Aportante: "CONCAT(ca.Nombre, ' ', ca.Paterno, ' ', ca.Materno)",
      Celular: 'ca.Celular',
      Nombre: 'cac.Nombre',
      Quincena: 'cac.Quincena',
      MontoCargo: 'cac.MontoCargo',
      FechaCargo: 'cac.FechaCargo',
      LineaCaptura: 'cac.LineaCaptura',
      FechaPago: 'cac.FechaPago',
      Estatus: 'caec.id',
    };

    setParams((prev) => ({ ...prev, sorted: [{ id: filters[orderBy], value: order }] }));
  };

  const handleFilter = (values) => setParams((prev) => ({ ...prev, filtered: values }));

  return (
    <Card>
      <LoadingForm
        loadinMessage={message}
        successMessage="Cargo eliminado con exito!"
        success={false}
        isLoading={isDeleting}
        isOpen={isDeleting}
        setIsOpen={() => {}}
      />

      {/* Descargar archivo exportado */}
      {isDownloading && (
        <Box marginBottom={2}>
          <Download format="pdf" isDownload={isDownloading} />
        </Box>
      )}

      <CustomModal
        open={open}
        onClose={handleClose}
        title={!isPagosForm ? 'Editar Cargo' : 'Validar comprobante'}
        fullWidth
        disableClose
      >
        {!isPagosForm ? (
          <Form
            type="edit"
            data={currentData}
            handleDelete={deleteConfirmation}
            handleClose={handleClose}
            handleIsEdited={handleIsEdited}
            catalogs={catalogs}
            isLoadingCat={isLoadingCat}
          />
        ) : (
          <PagosForm
            data={currentData}
            handleClose={handleClose}
            catalogs={catalogs}
            isLoadingCat={isLoadingCat}
            reloadCatalogs={reloadCatalogs}
            validateCargo={true}
            handleDeny={denyConfirmation}
            handleIsCreated={handleIsReset}
          />
        )}
      </CustomModal>

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Filter
              handleFilter={handleFilter}
              catalogs={catalogs}
              isLoadingCat={isLoadingCat}
              flags={{ nombre: true, celular: true, cat_estatus: true, cat_comprobante: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTable
              rows={data}
              columns={COLUMNS_CARGOS(canEdit || canDelete || canDownload)}
              total={total}
              page={params.page}
              pageSize={params.pageSize}
              isLoading={isLoading}
              handlePagination={handlePagination}
              handleSort={handleSort}
              disableCardType
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CargosView;
