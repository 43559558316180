import { useState } from 'react';
import { Swal, SwalDelete } from '@utils/alerts';
import { useFormik } from 'formik';

// Material UI y estilos
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  Icon,
  Box,
  CircularProgress,
  Divider,
  FormHelperText,
} from '@mui/material';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import LoadingForm from '@components/LinearProgress/LoadingForm';
import BasicListDoble from '@components/Lists/BasicListDoble';
import EventListItem from '@components/Events/EventListItem';
import PreguntasEventos from '@components/Events/PreguntasEventos';

// Servicios
import EventsServices from '@services/EventsServices';

// Utils
import { isEmptyOrNullObject } from '@utils/validations';
import { handleAttrs, getFechaActual } from '@utils/Utilities';

// Data
import { EventoPreguntasInterface } from '@interfaces/EventosInterfaces';
import { EventoPreguntasSchema } from '@schemas/EventosSchemas';

const PreguntasPost = ({ idTipoEvento = 0, type = 'edit' }) => {
  const filter = idTipoEvento ? [{ id: 'cat_actividades.eventType', filter: '=', value: idTipoEvento }] : [];

  const [canEditQuestions, setCanEditQuestions] = useState(true);

  const [event, setEvent] = useState({});

  const [isLoading, setIsLoading] = useState({ loading: false, message: 'Añadiendo preguntas' });
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);

  const formik = useFormik({
    initialValues: EventoPreguntasInterface,
    validationSchema: EventoPreguntasSchema,
    onSubmit: (values) => {
      handleAdd(handleAttrs(values));
    },
  });

  const getQuestions = async (idEvento) => {
    try {
      const localParams = {
        page: 0,
        pageSize: 10,
        filtered: [
          { id: 'idEvento', filter: '=', value: idEvento },
          { id: 'idTipoPregunta', filter: '=', value: 2 },
        ],
      };

      setIsLoadingQuestions(true);
      const result = await EventsServices.getQuestions(localParams);
      const { results, response, message } = result;

      if (results) {
        setCanEditQuestions(response.canEditPE);
        formik.setValues({ idEvento, preguntas: response.data });
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning', customClass: { container: 'modal-alert' } });
    } finally {
      setIsLoadingQuestions(false);
    }
  };

  const handleAdd = async (values) => {
    try {
      setIsLoading({ loading: true, message: 'Añadiendo preguntas' });

      const params = { idEvento: event.id, preguntas: values.preguntas, idTipoPregunta: 2 };

      const result = await EventsServices.setQuestions(params);
      const { results, message } = result;

      if (results) Swal.fire({ title: message, icon: 'success' });
      else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading((prev) => ({ ...prev, loading: false }));
    }
  };

  const deleteConfirmation = async ({ Actividad, id }) => {
    SwalDelete({
      title: 'Eliminar Preguntas',
      text: `¿Estas seguro de querer eliminar todas las preguntas del evento: ${Actividad}?`,
      buttonText: 'Eliminar preguntas',
      onDelete: () => handleDelete({ id }),
    });
  };

  const handleDelete = async ({ id }) => {
    try {
      setIsLoading({ loading: true, message: 'Eliminando preguntas...' });

      const result = await EventsServices.deleteQuestions({ idEvento: id, idTipoPregunta: 2 });
      const { results, message } = result;

      if (results) {
        getQuestions(id);
        Swal.fire({ title: message, icon: 'success' });
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleEvent = async (e) => {
    setEvent(e);
    getQuestions(e.id);
  };

  const loadAll = isLoading.loading || isLoadingQuestions;

  return (
    <AdminLayout key={idTipoEvento}>
      <ActionHeader title="Preguntas del Evento" />

      <LoadingForm
        loadinMessage={isLoading.message}
        successMessage={'Preguntas eliminadas con exito!'}
        isLoading={isLoading.loading}
        isOpen={isLoading.loading}
        setIsOpen={() => {}}
      />

      <Card className="card-primary">
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4.5} xl={6}>
              <BasicListDoble
                id="id_Eventos"
                API={EventsServices.getMyRecords}
                APIKeys={[{ id: ['eventos.Actividad'], filter: 'LIKE' }]}
                APIFilter={{
                  start: filter.concat([
                    { id: 'DATE(eventos.FechaInicio)', filter: '>=', value: getFechaActual() },
                  ]),
                }}
                header={false}
                handleClick={handleEvent}
                label={'Encuentra y elige el evento'}
                labelNote={'Nota: Para realizar una búsqueda debe ingresar el nombre del evento.'}
                finder={{ placeholder1: 'Buscar evento' }}
                initialSearch
                emptySearch
                config={{ height: 300 }}
                itemComponent={EventListItem}
              />
            </Grid>

            <Grid item xs={12} lg={7.5} xl={6}>
              <Box
                sx={{
                  mt: 4,
                  px: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                <Typography>
                  <strong>Evento:</strong> {event?.Actividad ?? 'No encontrado, debes ser staff.'}
                </Typography>

                {isLoadingQuestions ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                    <CircularProgress size={43} />
                    Cargando preguntas...
                  </Box>
                ) : (
                  !isEmptyOrNullObject(event) && (
                    <>
                      <PreguntasEventos
                        isLoading={loadAll}
                        formik={formik}
                        type={type}
                        title="Preguntas de asistencia"
                        canEdit={canEditQuestions}
                        info="asistencias"
                      />
                      <FormHelperText error>
                        {typeof formik.errors.preguntas === 'string' ? formik.errors.preguntas : ''}
                      </FormHelperText>
                    </>
                  )
                )}

                <Divider />

                <Box sx={{ mt: 1, display: 'flex', gap: 2 }}>
                  <Button
                    size="small"
                    color="success"
                    variant="contained"
                    startIcon={<Icon>add</Icon>}
                    onClick={formik.submitForm}
                    disabled={isEmptyOrNullObject(event) || loadAll}
                  >
                    Guardar Preguntas
                  </Button>

                  <Button
                    size="small"
                    color="error"
                    variant="contained"
                    startIcon={<Icon>delete</Icon>}
                    onClick={() => deleteConfirmation(event)}
                    disabled={isEmptyOrNullObject(event) || loadAll}
                  >
                    Eliminar Preguntas
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </AdminLayout>
  );
};

export default PreguntasPost;
