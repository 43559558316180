import { useState } from 'react';

// Material UI
import { Card, CardContent } from '@mui/material';

// Componentes
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import CustomTabs from '@components/Tabs/CustomTabs';
import View from '@components/Aportaciones/MisCargosView';

// Hooks
import { useCatalogs } from '@hooks/useCatalogs';
import { getVars } from '@utils/global';

const MisCargos = () => {
  const user = getVars('Token', {})?.user || {};

  const [tab, setTab] = useState(0);

  const catalogsOptions = [{ id: 'cat_aportantes_estatus_cargo' }];
  const { catalogs, load } = useCatalogs({
    catalogsOptions: catalogsOptions,
    putDefaultOption: false,
  });

  return (
    <AdminLayout>
      <ActionHeader title="Mis Cargos" />

      <Card className="card-primary">
        <CardContent>
          <CustomTabs
            value={tab}
            setValue={setTab}
            tabs={[
              {
                value: 0,
                label: 'Listado',
                icon: 'view_list',
                sxContent: { pt: 0 },
                component: <View catalogs={catalogs} isLoadingCat={load} idUser={user.id} />,
              },
            ]}
          />
        </CardContent>
      </Card>
    </AdminLayout>
  );
};

export default MisCargos;
