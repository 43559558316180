import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Swal, SwalConfirm } from '@utils/alerts';

// Material UI
import {
  Grid,
  Button,
  Card,
  CardContent,
  TextField,
  FormHelperText,
  Box,
  List,
  ListItem,
  ListItemText,
  Checkbox,
} from '@mui/material';

// Componentes
import LoadingForm from '@components/LinearProgress/LoadingForm';
import CustomListMultiple from '@components/Lists/CustomListMultiple';

// Utils
import { handleAttrs, floatWithCommas } from '@utils/Utilities';
import { isEmptyOrNullObject, isTypePhone } from '@utils/validations';

// Servicios
import services from '@services/AportacionesServices';

// Data
import { JoinCargosInterface } from '@interfaces/AportacionesInterfaces';
import { JoinCargosSchema } from '@schemas/AportacionesSchemas';
import { estatus_cargo, estatus_pagado } from '@data/constants/Aportaciones';

const CargosJoin = (props) => {
  const formik = useFormik({
    initialValues: JoinCargosInterface,
    validationSchema: JoinCargosSchema,
    onSubmit: (values) => {
      handleJoin(handleAttrs(values));
    },
  });

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMessage, setIsLoadingMessage] = useState('Uniendo cargos...');

  const [reload, setReload] = useState(false);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    if (!isEmptyOrNullObject(selected) && page) getData(selected.id); //eslint-disable-next-line
  }, [page]);

  const getData = async (idAportante, PageZero = false, resetData = false) => {
    const newData = resetData ? [] : data;
    const newPage = PageZero ? 0 : page;

    if (total && newData >= total) return;

    try {
      setIsLoading(true);

      const result = await services.getCargos({
        page: newPage,
        pageSize: 10,
        filtered: [
          { id: 'cac.idAportante', filter: '=', value: idAportante },
          {
            id: 'cac.idEstatusCargo',
            filter: 'NOT IN',
            value: [estatus_cargo.pagado, estatus_cargo.sustituido],
          },
          { id: 'cac.isPagado', filter: '=', value: estatus_pagado.no },
          { id: 'cac.FechaPago', filter: 'IS', value: null },
        ],
      });
      const { results, response, message } = result;

      if (results) {
        setData([...newData, ...response.data]);
        setTotal(response.total);
      } else throw new Error(message);
    } catch (e) {
      setData([]);
      setTotal(0);
      setPage(0);
      Swal.fire({ title: e.message, icon: 'warning', customClass: { container: 'modal-alert' } });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = async (id) => {
    try {
      setIsLoading(true);
      setIsLoadingMessage('Descargando cargo...');
      const result = await services.downloadCargos({ id });
      if (!result.results) throw new Error(result.message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleJoin = async (params) => {
    try {
      setIsLoading(true);
      setIsLoadingMessage('Uniendo cargos...');

      const result = await services.joinCargos(params);
      const { success, results, response, message } = result;

      if (success && results) {
        formik.resetForm();
        setReload(!reload);
        setSelected({});
        setData([]);
        setTotal(0);
        setPage(0);

        SwalConfirm({
          icon: 'success',
          title: message,
          text: `¿Quieres descargar el PDF?`,
          OnConfirm: () => handleDownload(response.idCargo),
        });
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const verify = (name) => {
    if (!formik.touched || !formik.touched[name]) return {};
    if (!formik.errors || !formik.errors[name]) return {};

    return {
      error: formik.touched[name] && Boolean(formik.errors[name]),
      helperText: formik.touched[name] && formik.errors[name],
    };
  };

  const handleUser = (e) => {
    setSelected(e);
    setPage(0);
    getData(e.id, true, true);
    formik.setValues({ idsCargos: [], idAportante: e.id });
  };
  const handleClear = () => {
    setSelected({});
    setPage(0);
    setData([]);
    formik.setValues({ idsCargos: [], idAportante: '' });
  };

  const handleSelected = (id) => {
    const ids = formik.values.idsCargos;

    let newData = { idsCargos: [...ids, id] };
    if (ids.includes(id)) newData = { idsCargos: ids.filter((item) => item !== id) };

    const len = newData.idsCargos?.length;

    newData.Nombre = `${len || 0} cargo${len === 1 ? '' : 's'} agrupado${len === 1 ? '' : 's'}`;

    const nombres = data.filter((item) => newData.idsCargos.includes(item.id)).map((item) => item.Nombre);
    newData.Descripcion = `Se agruparon los cargos: '${nombres?.join("', '")}'`;

    formik.setValues({ ...formik.values, ...newData });
  };

  const loadAll = false;

  return (
    <>
      <LoadingForm
        loadinMessage={isLoadingMessage}
        successMessage="Cargos unidos con exito!"
        success={false}
        isLoading={isLoading}
        isOpen={isLoading}
        setIsOpen={() => {}}
      />

      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <CustomListMultiple
                id="id_Aportante"
                label="Encuentra y elige al aportante"
                labelNote="Nota: Para realizar una búsqueda debe ingresar un nombre o por el contrario un número de celular."
                API={services.getAportantesWithCargos}
                APIKeys={{
                  input_1: { id: ['ca.Nombre', 'ca.Paterno', 'ca.Materno'], filter: 'LIKE' },
                  input_2: { id: 'ca.Celular', filter: '=' },
                }}
                placeholders={{
                  input_1: 'Francisco Bueno',
                  input_2: '0000000000',
                }}
                inputValidation={{ input_2: isTypePhone }}
                lengthValidation={{ input_2: { filter: '=', value: 10 } }}
                handleClick={handleUser}
                handleClear={handleClear}
                clearData
                emptySearch
                initialSearch
                reload={reload}
                disableHeader
              />
            </Grid>

            <Grid item xs={12} md={6} lg={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TextField
                    size="small"
                    fullWidth
                    label="Nombre del Aportante"
                    value={(
                      (selected?.Nombre || '') +
                      ' ' +
                      (selected?.Paterno || '') +
                      ' ' +
                      (selected?.Materno || '')
                    ).trim()}
                    disabled
                    error={verify('idAportante')?.error}
                    helperText={verify('idAportante')?.helperText}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TextField
                    required
                    size="small"
                    fullWidth
                    name="Nombre"
                    label="Nombre del cargo"
                    placeholder="Nombre del cargo"
                    value={formik.values.Nombre}
                    onChange={formik.handleChange}
                    disabled={loadAll}
                    error={verify('Nombre')?.error}
                    helperText={verify('Nombre')?.helperText}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    size="small"
                    fullWidth
                    name="Descripcion"
                    label="Descripción del cargo"
                    placeholder="Este cargo..."
                    value={formik.values.Descripcion}
                    onChange={formik.handleChange}
                    disabled={loadAll}
                    error={verify('Descripcion')?.error}
                    helperText={verify('Descripcion')?.helperText}
                    multiline
                    minRows={2}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ maxHeight: 525, overflow: 'auto', borderRadius: '4px' }}>
                    <List>
                      {data?.map((item) => (
                        <ListItem key={item.id} button onClick={() => handleSelected(item.id)}>
                          <Checkbox
                            checked={formik.values.idsCargos.includes(item.id)}
                            onChange={() => handleSelected(item.id)}
                          />
                          <ListItemText
                            primary={item.Nombre}
                            secondary={`Monto: ${floatWithCommas(item.MontoCargo)} - Estatus: ${
                              item.Estatus
                            }`}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>

                  {verify('idsCargos')?.error && (
                    <Grid item xs={12}>
                      <FormHelperText error>{verify('idsCargos')?.helperText}</FormHelperText>
                    </Grid>
                  )}

                  {data.length < total && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setPage((prev) => prev + 1)}
                      sx={{ mt: '10px' }}
                      fullWidth
                    >
                      Cargar más
                    </Button>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2} justifyContent={'end'}>
                    <Grid item xs={12} sm={5} md={4} lg={3}>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={formik.handleSubmit}
                        disabled={loadAll}
                        fullWidth
                      >
                        Agrupar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default CargosJoin;
