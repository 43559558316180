import { useState, useEffect } from 'react';
import esLocale from 'date-fns/locale/es';
import { isBefore } from 'date-fns';
import { useFormik } from 'formik';
import { Swal, SwalImage } from '@utils/alerts';

// Material UI
import {
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  InputAdornment,
  IconButton,
  CardMedia,
} from '@mui/material';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Clear } from '@mui/icons-material';

// Componentes
import LoadingForm from '@components/LinearProgress/LoadingForm';
import AdvancedSelect from '@components/Selects/AdvancedSelect';
import CustomListMultiple from '@components/Lists/CustomListMultiple';

// Utils
import { handleAttrs, floatWithCommas, getDate, handleFormikSubmit } from '@utils/Utilities';
import { isEmptyOrNullObject, isTypePhone } from '@utils/validations';

// Servicios
import services from '@services/AportacionesServices';
import CatalogService from '@services/CatalogServices';

// Data
import { EditPagosInterface, PagosInterface } from '@interfaces/AportacionesInterfaces';
import { EditPagosSchema, PagosSchema } from '@schemas/AportacionesSchemas';
import { estatus_cargo, estatus_pagado } from '@data/constants/Aportaciones';

// Middleware
import middleware from '@middlewares/middleware';

const PagosForm = (props) => {
  const {
    type = 'create',
    data = null,
    handleDelete = () => {},
    handleClose = () => {},
    handleIsEdited = () => {},
    handleIsCreated = () => {},
    reloadCatalogs = () => {},
    handleDeny = () => {},
    catalogs,
    isLoadingCat,
    validateCargo = false,
  } = props;

  const isEdit = type === 'edit';

  const formik = useFormik({
    initialValues: isEdit ? EditPagosInterface : PagosInterface,
    validationSchema: isEdit ? EditPagosSchema : PagosSchema,
    onSubmit: (values) => {
      if (isEdit) handleEdit(handleAttrs(values));
      else handleCreate(handleAttrs(values));
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMessage, setIsLoadingMessage] = useState('Creando pago...');
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (!isEmptyOrNullObject(data)) {
      const newData = { ...data };
      delete newData.Aportante;
      delete newData.Celular;
      delete newData.Nombre;
      delete newData.Estatus;
      delete newData.TipoPago;
      delete newData.MontoCargo;
      delete newData.FechaCargo;
      delete newData.FileURL;

      formik.setValues(newData);
    } // eslint-disable-next-line
  }, [data]);

  const handleEdit = async (params) => {
    const localParams = {
      ...params,
      FechaPago: params.FechaPago ? getDate({ fecha: params.FechaPago }) : null,
      MontoPagado: parseFloat(params.MontoPagado.replace(/,/g, '')),
    };

    try {
      setIsLoading(true);
      setIsLoadingMessage('Editando pago...');

      const result = await services.updatePago(localParams);
      const { success, results, message } = result;

      if (success && results) {
        handleIsEdited();
        Swal.fire({ title: message, icon: 'success' });
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreate = async (params) => {
    const localParams = {
      ...params,
      FechaPago: params.FechaPago ? getDate({ fecha: params.FechaPago }) : null,
      MontoPagado: parseFloat(params.MontoPagado.replace(/,/g, '')),
    };

    try {
      setIsLoading(true);
      setIsLoadingMessage('Creando pago...');

      const result = await services.createPago(localParams);
      const { success, results, message } = result;

      if (success && results) {
        handleIsCreated();
        Swal.fire({ title: message, icon: 'success' });
        formik.resetForm();
        setReload(!reload);
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddItem = async ({ value }) => {
    const Banco = value?.trim();

    try {
      setIsLoading(true);
      setIsLoadingMessage('Creando banco...');

      const result = await services.createBanco({ Banco });
      const { success, results, message } = result;

      if (success && results) {
        reloadCatalogs();
        Swal.fire({ title: message, icon: 'success' });
        formik.setValues({ ...formik.values, Banco });
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const loadAll = isLoadingCat;

  return (
    <>
      <LoadingForm
        loadinMessage={isLoadingMessage}
        successMessage="Pago creado con exito!"
        success={false}
        isLoading={isLoading}
        isOpen={isLoading}
        setIsOpen={() => {}}
      />

      {isEdit || validateCargo ? (
        <Form
          formik={formik}
          loadAll={loadAll}
          isEdit={isEdit}
          handleClose={handleClose}
          handleDelete={handleDelete}
          handleDeny={handleDeny}
          handleAddItem={handleAddItem}
          data={data}
          catalogs={catalogs}
          reload={reload}
          validateCargo={validateCargo}
        />
      ) : (
        <Card>
          <CardContent>
            <Form
              formik={formik}
              loadAll={loadAll}
              isEdit={isEdit}
              handleClose={handleClose}
              handleDelete={handleDelete}
              handleAddItem={handleAddItem}
              data={data}
              catalogs={catalogs}
              reload={reload}
            />
          </CardContent>
        </Card>
      )}
    </>
  );
};

const Form = (props) => {
  const {
    formik,
    loadAll,
    isEdit,
    handleClose = () => {},
    handleDelete = () => {},
    handleDeny = () => {},
    handleAddItem = () => {},
    data,
    catalogs,
    reload,
    validateCargo = false,
  } = props;

  const [selected, setSelected] = useState({});
  const [initial, setInitial] = useState(true);

  const [isLoadingCargos, setIsLoadingCargos] = useState(false);
  const [cargos, setCargos] = useState([]);

  const verify = (name) => {
    if (!formik.touched || !formik.touched[name]) return {};
    if (!formik.errors || !formik.errors[name]) return {};

    return {
      error: formik.touched[name] && Boolean(formik.errors[name]),
      helperText: formik.touched[name] && formik.errors[name],
    };
  };

  const loadCargos = async (value) => {
    const variableParams =
      isEdit || validateCargo
        ? [{ id: 'id', filter: '=', value: data.idCargo }]
        : [
            {
              id: 'idEstatusCargo',
              filter: 'NOT IN',
              value: [estatus_cargo.pagado, estatus_cargo.sustituido],
            },
            { id: 'isPagado', filter: '=', value: estatus_pagado.no },
            { id: 'FechaPago', filter: 'IS', value: null },
          ];

    const catParams = [
      {
        id: 'cat_aportantes_cargos',
        filtered: [{ id: 'idAportante', filter: '=', value }, ...variableParams],
      },
    ];

    try {
      setIsLoadingCargos(true);
      const result = await CatalogService.getCatalogs(catParams);
      const { success, results, response, message } = result;

      if (success && results) setCargos(response.catalogs.cat_aportantes_cargos);
      else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoadingCargos(false);
    }
  };

  const handleUser = (e) => {
    setSelected(e);
    loadCargos(e.id);

    if ((isEdit && initial) || (validateCargo && initial)) {
      setInitial(false);
      return;
    }

    formik.setValues({ ...formik.values, idAportante: e.id, idCargo: '' });
  };
  const handleClear = () => {
    setSelected({});
    setCargos([]);
    formik.setValues({ ...formik.values, idAportante: '', idCargo: '' });
  };

  const handleCargo = ({ value, name, MontoCargo }) => {
    formik.setValues({ ...formik.values, [name]: value, MontoPagado: floatWithCommas(MontoCargo) });
  };

  useEffect(() => {
    if (!isEdit && !validateCargo) formik.setValues({ ...PagosInterface, FechaPago: new Date() });
    setSelected({});
    setCargos([]); // eslint-disable-next-line
  }, [reload]);

  return (
    <Grid container spacing={2}>
      {data?.FileURL ? (
        <Grid item xs={12} sm={6} lg={4}>
          <CardMedia
            component="img"
            sx={{ overflow: 'auto', px: 1, cursor: 'pointer' }}
            image={data?.FileURL}
            alt="Comprobante del pago"
            onClick={() => SwalImage({ image: data.FileURL, title: data.Nombre || 'Comprobante del pago' })}
            onLoad={() => {
              const button = document.getElementById('idAportante_MTK');
              if (button) button.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }}
          />
        </Grid>
      ) : (
        <></>
      )}

      <Grid item xs={12} sm={6} lg={4} sx={{ display: validateCargo || data?.FileURL ? 'none' : '' }}>
        <CustomListMultiple
          id="id_Aportante"
          label="Encuentra y elige al aportante"
          labelNote="Nota: Para realizar una búsqueda debe ingresar un nombre o por el contrario un número de celular."
          API={isEdit ? services.getAportantes : services.getAportantesWithCargos}
          APIKeys={{
            input_1: { id: ['ca.Nombre', 'ca.Paterno', 'ca.Materno'], filter: 'LIKE' },
            input_2: { id: 'ca.Celular', filter: '=' },
          }}
          placeholders={{
            input_1: 'Francisco Bueno',
            input_2: '0000000000',
          }}
          initialValues={{
            input_1: data?.Aportante || '',
            input_2: data?.Celular || '',
          }}
          inputValidation={{ input_2: isTypePhone }}
          lengthValidation={{ input_2: { filter: '=', value: 10 } }}
          handleClick={handleUser}
          handleClear={handleClear}
          clearData
          emptySearch
          initialSearch
          selectFirst={(isEdit && initial) || (validateCargo && initial)}
          reload={reload}
          disabled={isEdit || validateCargo}
          disableHeader
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={8}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextField
              id="idAportante_MTK"
              size="small"
              fullWidth
              label="Nombre del Aportante"
              value={(
                (selected?.Nombre || '') +
                ' ' +
                (selected?.Paterno || '') +
                ' ' +
                (selected?.Materno || '')
              ).trim()}
              disabled
              error={verify('idAportante')?.error}
              helperText={verify('idAportante')?.helperText}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <AdvancedSelect
              required
              size="small"
              fullWidth
              id="idCargo"
              name="idCargo"
              label="Cargo *"
              options={cargos}
              value={formik.values.idCargo}
              onChange={handleCargo}
              isLoading={isLoadingCargos || loadAll}
              disabled={isEdit || validateCargo}
              error={verify('idCargo')?.error}
              helperText={verify('idCargo')?.helperText}
              isSearchable
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <TextField
              required
              size="small"
              fullWidth
              name="MontoPagado"
              label="Monto pagado"
              placeholder="1,000"
              value={formik.values.MontoPagado}
              onChange={({ target: { value, name } }) =>
                formik.setValues({ ...formik.values, [name]: floatWithCommas(value) })
              }
              disabled
              error={verify('MontoPagado')?.error}
              helperText={verify('MontoPagado')?.helperText}
              InputProps={{
                endAdornment: <InputAdornment position="end">$</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
              <MobileDateTimePicker
                label={`Fecha de pago *`}
                value={formik.values.FechaPago}
                onChange={(date) => formik.setValues({ ...formik.values, FechaPago: date ?? '' })}
                shouldDisableDate={(date) => !isBefore(date, new Date())}
                inputFormat="yyyy-MM-dd HH:mm:ss"
                orientation="portrait"
                disabled={loadAll}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    disabled={loadAll}
                    sx={{ width: '100%' }}
                    error={verify('FechaPago')?.error}
                    helperText={verify('FechaPago')?.helperText}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              formik.setValues({ ...formik.values, FechaPago: new Date() });
                            }}
                            disabled={loadAll}
                          >
                            Hoy
                          </IconButton>

                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              formik.setValues({ ...formik.values, FechaPago: '' });
                            }}
                            disabled={loadAll}
                          >
                            <Clear />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} lg={6}>
            <AdvancedSelect
              required
              size="small"
              fullWidth
              id="idTipoPago"
              name="idTipoPago"
              label="Tipo de pago *"
              options={catalogs.cat_aportantes_tipo_pago}
              value={formik.values.idTipoPago}
              onChange={({ value, name }) => formik.setValues({ ...formik.values, [name]: value })}
              isLoading={loadAll}
              error={verify('idTipoPago')?.error}
              helperText={verify('idTipoPago')?.helperText}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <AdvancedSelect
              required
              size="small"
              fullWidth
              id="Banco"
              name="Banco"
              label="Banco *"
              options={catalogs.cat_aportantes_bancos}
              value={formik.values?.Banco?.toUpperCase()}
              onChange={(e) => formik.setValues({ ...formik.values, Banco: e.label?.toUpperCase() })}
              isLoading={loadAll}
              error={verify('Banco')?.error}
              helperText={verify('Banco')?.helperText}
              isSearchable
              valueToUpperCase
              handleAddItem={handleAddItem}
              formatValue={(value) =>
                value
                  ?.replace(/[^a-zA-Z0-9 ]/g, '')
                  ?.replace(/\s+/g, ' ')
                  ?.toUpperCase()
                  ?.slice(0, 30)
              }
              searchBy="label"
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent={'end'}>
              {(isEdit || validateCargo) && (
                <Grid item xs={12} sm={5} md={4} lg={3}>
                  <Button variant="contained" color="warning" onClick={handleClose} fullWidth>
                    Cancelar
                  </Button>
                </Grid>
              )}

              {isEdit && middleware.checkMenuAction('Eliminar') && (
                <Grid item xs={12} sm={5} md={4} lg={3}>
                  <Button variant="contained" color="error" onClick={() => handleDelete(data)} fullWidth>
                    Eliminar
                  </Button>
                </Grid>
              )}

              <Grid item xs={12} sm={5} md={4} lg={3}>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => handleFormikSubmit(formik)}
                  disabled={isLoadingCargos || loadAll}
                  fullWidth
                >
                  {isEdit ? 'Editar' : validateCargo ? 'Aceptar Pago' : 'Agregar'}
                </Button>
              </Grid>

              {validateCargo && (
                <Grid item xs={12} sm={5} md={4} lg={3}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleDeny(data)}
                    disabled={isLoadingCargos || loadAll}
                    fullWidth
                  >
                    Rechazar
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PagosForm;
