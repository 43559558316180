import { useState, useEffect } from 'react';
import esLocale from 'date-fns/locale/es';
import { isBefore } from 'date-fns';
import { useFormik } from 'formik';
import { Swal } from '@utils/alerts';

// Material UI
import {
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  InputAdornment,
  Divider,
  Typography,
  IconButton,
} from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Clear, Email, Smartphone, Facebook, X, Instagram, Interests } from '@mui/icons-material';

// Componentes
import LoadingForm from '@components/LinearProgress/LoadingForm';
import AdvancedSelect from '@components/Selects/AdvancedSelect';
import AutoComplete from '@components/Selects/Autocomplete';
import CustomListMultiple from '@components/Lists/CustomListMultiple';
import FilterCollapse from '@components/Filters/FilterCollapse';
import { showSnackbar } from '@components/Alerts/CustomSnack';

// Utils
import { handleAttrs, getDate, handleFormikSubmit, scrollByID } from '@utils/Utilities';
import { isTypePhone, isEmptyOrNullObject } from '@utils/validations';

// Servicios
import services from '@services/AportacionesServices';
import CatalogService from '@services/CatalogServices';
import UserService from '@services/UserServices';

// Data
import { EditAportantesInterface, AportantesInterface } from '@interfaces/AportacionesInterfaces';
import { EditAportantesSchema, AportantesSchema } from '@schemas/AportacionesSchemas';

// Middleware
import middleware from '@middlewares/middleware';

const AportantesForm = (props) => {
  const {
    type = 'create',
    data = null,
    handleDelete = () => {},
    handleClose = () => {},
    handleIsEdited = () => {},
    catalogs,
    isLoadingCat,
  } = props;

  const isEdit = type === 'edit';

  const formik = useFormik({
    initialValues: isEdit ? EditAportantesInterface : AportantesInterface,
    validationSchema: isEdit ? EditAportantesSchema : AportantesSchema,
    onSubmit: (values) => {
      if (isEdit) handleEdit(handleAttrs(values));
      else handleCreate(handleAttrs(values));
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMessage, setIsLoadingMessage] = useState('Creando aportante...');

  useEffect(() => {
    if (!isEmptyOrNullObject(data)) {
      const newData = { ...data };
      delete newData.Municipio;
      delete newData.Sexo;
      delete newData.FechaNacimiento;

      formik.setValues(newData);
    } // eslint-disable-next-line
  }, [data]);

  const handleEdit = async (params) => {
    const localParams = {
      ...params,
      FechaAltaRNM: params.FechaAltaRNM ? getDate({ fecha: params.FechaAltaRNM, hora: false }) : null,
    };

    try {
      setIsLoading(true);
      setIsLoadingMessage('Editando aportante...');

      const result = await services.updateAportante(localParams);
      const { success, results, message } = result;

      if (success && results) {
        handleIsEdited();
        Swal.fire({ title: message, icon: 'success' });
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreate = async (params) => {
    const localParams = {
      ...params,
      FechaAltaRNM: params.FechaAltaRNM ? getDate({ fecha: params.FechaAltaRNM, hora: false }) : null,
    };

    try {
      setIsLoading(true);
      setIsLoadingMessage('Creando aportante...');

      const result = await services.createAportante(localParams);
      const { success, results, message } = result;

      if (success && results) {
        Swal.fire({ title: message, icon: 'success' });
        formik.resetForm();
      } else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const loadAll = isLoadingCat;

  return (
    <>
      <LoadingForm
        loadinMessage={isLoadingMessage}
        successMessage="Aportante creado con exito!"
        success={false}
        isLoading={isLoading}
        isOpen={isLoading}
        setIsOpen={() => {}}
      />

      {isEdit ? (
        <Form
          formik={formik}
          loadAll={loadAll}
          isEdit={isEdit}
          handleClose={handleClose}
          handleDelete={handleDelete}
          data={data}
          catalogs={catalogs}
        />
      ) : (
        <Card>
          <CardContent>
            <Form
              formik={formik}
              loadAll={loadAll}
              isEdit={isEdit}
              handleClose={handleClose}
              handleDelete={handleDelete}
              data={data}
              catalogs={catalogs}
            />
          </CardContent>
        </Card>
      )}
    </>
  );
};

const Form = ({ formik, loadAll, isEdit, handleClose, handleDelete, data, catalogs }) => {
  const [isLoadingColonias, setIsLoadingColonias] = useState(false);
  const [catColonias, setCatColonias] = useState([]);

  const loadCatalogsByMunicipio = async ({ value }) => {
    formik.setValues({
      ...formik.values,
      idMunicipio: value,
      Colonia: '',
    });
    const catParams = [{ id: 'colonias', filtered: [{ id: 'idMunicipio', filter: '=', value: value }] }];

    try {
      setIsLoadingColonias(true);
      const result = await CatalogService.getCatalogs(catParams);
      const { success, results, response, message } = result;

      if (success && results) setCatColonias(response.catalogs.colonias || []);
      else throw new Error(message);
    } catch (e) {
      Swal.fire({ title: e.message, icon: 'warning' });
    } finally {
      setIsLoadingColonias(false);
    }
  };

  const verify = (name) => {
    if (!formik.touched || !formik.touched[name]) return {};
    if (!formik.errors || !formik.errors[name]) return {};

    return {
      error: formik.touched[name] && Boolean(formik.errors[name]),
      helperText: formik.touched[name] && formik.errors[name],
    };
  };

  const handlePaste = async (values) => {
    const newData = { ...values };
    newData.idUsuarioSIAN = newData.id;

    delete newData.id;
    delete newData.Municipio;
    delete newData.Sexo;
    delete newData.Username;
    delete newData.FechaNacimiento;

    formik.setValues({ ...AportantesInterface, ...newData });
    showSnackbar({ message: 'Se añadierón algunos datos automaticamente', color: 'success' });

    scrollByID({ id: 'initial-form' });
  };

  const handleClear = () => formik.resetForm();

  return (
    <Grid container spacing={2}>
      {/* Filtro SIAN */}
      {!isEdit && (
        <Grid item xs={12}>
          <FilterCollapse expand={false} title="BUSCADOR USUARIO SIAN">
            <CustomListMultiple
              id="id_Usuario_SIAN"
              label="Encuentra y elige a un usuario de SIAN"
              labelNote="Nota: Al dar click en un usuario, se rellenaran los datos disponibles."
              API={UserService.getUsersInfo}
              APIKeys={{
                input_1: { id: ['u.Nombre', 'u.Paterno', 'u.Materno'], filter: 'LIKE' },
                input_2: { id: 'u.Celular', filter: '=' },
                input_3: { id: 'u.INE', filter: '=' },
              }}
              labels={{ input_3: 'Buscar por INE' }}
              placeholders={{
                input_1: 'Francisco Bueno',
                input_2: '0000000000',
                input_3: 'XXXXXX00000000X000',
              }}
              inputValidation={{ input2: isTypePhone }}
              lengthValidation={{
                input_2: { filter: '=', value: 10 },
                input_3: { filter: '=', value: 18 },
              }}
              handleClick={handlePaste}
              disableHeader
              clearData
              breaks={{ sm: 6, lg: 4 }}
              listBreaks={{ md: 6 }}
              config={{ height: 250 }}
              itemValues={{
                primary: 'item.Nombre, item.Celular - item.FechaNacimiento',
                secondary: 'item.Municipio',
              }}
            />
          </FilterCollapse>
        </Grid>
      )}

      {/* Datos RNM */}
      <Grid item xs={12} id="initial-form">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              fullWidth
              name="RNM"
              label={`Registro Nacional de Militante${formik.values.FechaAltaRNM ? ' *' : ''}`}
              placeholder="xxxx"
              value={formik.values.RNM}
              onChange={formik.handleChange}
              disabled={loadAll}
              error={verify('RNM')?.error}
              helperText={verify('RNM')?.helperText}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
              <MobileDatePicker
                label={`Fecha de Alta${formik.values.RNM ? ' *' : ''}`}
                value={formik.values.FechaAltaRNM}
                onChange={(date) => formik.setValues({ ...formik.values, FechaAltaRNM: date ?? '' })}
                shouldDisableDate={(date) => !isBefore(date, new Date())}
                inputFormat="yyyy-MM-dd"
                orientation="portrait"
                disabled={loadAll}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    disabled={loadAll}
                    sx={{ width: '100%' }}
                    error={verify('FechaAltaRNM')?.error}
                    helperText={verify('FechaAltaRNM')?.helperText}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              formik.setValues({ ...formik.values, FechaAltaRNM: '' });
                            }}
                            disabled={loadAll}
                          >
                            <Clear />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>

      <LocalDivider title="Datos de Documentos" />

      {/* Datos Documentos */}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={5}>
            <TextField
              required
              size="small"
              fullWidth
              name="CURP"
              label="CURP"
              placeholder="XXXX000000XXXXXXX0"
              value={formik.values.CURP}
              onChange={({ target: { value, name } }) => {
                const newValue = value.toUpperCase().replace(/[^A-Z0-9]/g, '');
                if (newValue.length <= 18) formik.handleChange({ target: { value: newValue, name } });
              }}
              disabled={loadAll}
              error={verify('CURP')?.error}
              helperText={verify('CURP')?.helperText}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={5}>
            <TextField
              required
              size="small"
              fullWidth
              name="INE"
              label="INE"
              placeholder="XXXXXX00000000X000"
              value={formik.values.INE}
              onChange={({ target: { value, name } }) => {
                const newValue = value.toUpperCase().replace(/[^A-Z0-9]/g, '');
                if (newValue.length <= 18) formik.handleChange({ target: { value: newValue, name } });
              }}
              disabled={loadAll}
              error={verify('INE')?.error}
              helperText={verify('INE')?.helperText}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <TextField
              required
              size="small"
              fullWidth
              name="SeccionVota"
              label="Seccion Vota"
              placeholder="1234"
              value={formik.values.SeccionVota}
              onChange={({ target: { value, name } }) => {
                const newValue = value.replace(/[^0-9]/g, '');
                if (newValue.length <= 4) formik.handleChange({ target: { value: newValue, name } });
              }}
              disabled={loadAll}
              error={verify('SeccionVota')?.error}
              helperText={verify('SeccionVota')?.helperText}
            />
          </Grid>
        </Grid>
      </Grid>

      <LocalDivider title="Datos Basicos" />

      {/* Datos Basicos */}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              required
              size="small"
              fullWidth
              name="Nombre"
              label="Nombre"
              placeholder="Juan Diego"
              value={formik.values.Nombre}
              onChange={formik.handleChange}
              disabled={loadAll}
              error={verify('Nombre')?.error}
              helperText={verify('Nombre')?.helperText}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              required
              size="small"
              fullWidth
              name="Paterno"
              label="Paterno"
              placeholder="Perez"
              value={formik.values.Paterno}
              onChange={formik.handleChange}
              disabled={loadAll}
              error={verify('Paterno')?.error}
              helperText={verify('Paterno')?.helperText}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              size="small"
              fullWidth
              name="Materno"
              label="Materno"
              placeholder="Sanchez"
              value={formik.values.Materno}
              onChange={formik.handleChange}
              disabled={loadAll}
              error={verify('Materno')?.error}
              helperText={verify('Materno')?.helperText}
            />
          </Grid>
        </Grid>
      </Grid>

      <LocalDivider title="Domicilio" />

      {/* Domicilio */}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={4}>
            <AdvancedSelect
              required
              size="small"
              fullWidth
              id="idMunicipio"
              name="idMunicipio"
              label="Municipio *"
              options={catalogs.municipios || []}
              value={formik.values.idMunicipio}
              onChange={loadCatalogsByMunicipio}
              isLoading={loadAll}
              error={verify('idMunicipio')?.error}
              helperText={verify('idMunicipio')?.helperText}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <AutoComplete
              required
              size="small"
              id="Colonia"
              name="Colonia"
              label="Colonia *"
              autoComplete="Colonia"
              data={catColonias}
              value={formik.values.Colonia}
              setSelect={(e) => formik.setValues({ ...formik.values, Colonia: e.label })}
              loading={isLoadingColonias || loadAll}
              disabled={isLoadingColonias || loadAll || !formik.values.idMunicipio}
              onChange={formik.handleChange}
              error={verify('Colonia')?.error}
              helperText={verify('Colonia')?.helperText}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              required
              size="small"
              fullWidth
              name="Calle"
              label="Calle"
              placeholder="Calle"
              value={formik.values.Calle}
              onChange={formik.handleChange}
              disabled={loadAll}
              error={verify('Calle')?.error}
              helperText={verify('Calle')?.helperText}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              size="small"
              fullWidth
              name="NumExt"
              label="NumExt"
              placeholder="123"
              value={formik.values.NumExt}
              onChange={formik.handleChange}
              disabled={loadAll}
              error={verify('NumExt')?.error}
              helperText={verify('NumExt')?.helperText}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              size="small"
              fullWidth
              name="NumInt"
              label="NumInt"
              placeholder="2"
              value={formik.values.NumInt}
              onChange={formik.handleChange}
              disabled={loadAll}
              error={verify('NumInt')?.error}
              helperText={verify('NumInt')?.helperText}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              size="small"
              fullWidth
              name="CP"
              label="CP"
              placeholder="Col. Ejemplo"
              value={formik.values.CP}
              onChange={({ target: { value, name } }) => {
                if (isTypePhone(value)) formik.handleChange({ target: { value, name } });
              }}
              disabled={loadAll}
              error={verify('CP')?.error}
              helperText={verify('CP')?.helperText}
            />
          </Grid>
        </Grid>
      </Grid>

      <LocalDivider title="Redes Sociales" />

      {/* Redes sociales */}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7} md={6} lg={4}>
            <TextField
              required
              size="small"
              fullWidth
              type="email"
              name="Correo"
              label="Correo Electronico"
              placeholder="example@gmail.com"
              value={formik.values.Correo}
              onChange={formik.handleChange}
              disabled={loadAll}
              error={verify('Correo')?.error}
              helperText={verify('Correo')?.helperText}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={5} md={6} lg={4}>
            <TextField
              required
              size="small"
              fullWidth
              name="Celular"
              label="Celular"
              placeholder="1234567890"
              value={formik.values.Celular}
              onChange={({ target: { value, name } }) => {
                if (isTypePhone(value)) formik.handleChange({ target: { value, name } });
              }}
              disabled={loadAll}
              error={verify('Celular')?.error}
              helperText={verify('Celular')?.helperText}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Smartphone />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <TextField
              size="small"
              fullWidth
              name="Facebook"
              label="Facebook"
              placeholder="https://www.facebook.com/user"
              value={formik.values.Facebook}
              onChange={formik.handleChange}
              disabled={loadAll}
              error={verify('Facebook')?.error}
              helperText={verify('Facebook')?.helperText}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Facebook />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <TextField
              size="small"
              fullWidth
              name="X"
              label="X"
              placeholder="https://x.com/user"
              value={formik.values.X}
              onChange={formik.handleChange}
              disabled={loadAll}
              error={verify('X')?.error}
              helperText={verify('X')?.helperText}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <X />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <TextField
              size="small"
              fullWidth
              name="Instagram"
              label="Instagram"
              placeholder="https://www.instagram.com/user"
              value={formik.values.Instagram}
              onChange={formik.handleChange}
              disabled={loadAll}
              error={verify('Instagram')?.error}
              helperText={verify('Instagram')?.helperText}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Instagram />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <TextField
              size="small"
              fullWidth
              name="TikTok"
              label="TikTok"
              placeholder="https://www.tiktok.com/@user"
              value={formik.values.TikTok}
              onChange={formik.handleChange}
              disabled={loadAll}
              error={verify('TikTok')?.error}
              helperText={verify('TikTok')?.helperText}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Interests />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent={'end'}>
          {isEdit && (
            <Grid item xs={12} sm={4} md={2} lg={2}>
              <Button variant="contained" color="warning" onClick={handleClose} fullWidth>
                Cancelar
              </Button>
            </Grid>
          )}

          <Grid item xs={12} sm={4} md={2} lg={2}>
            <Button variant="contained" color="warning" onClick={handleClear} fullWidth>
              Limpiar
            </Button>
          </Grid>

          {isEdit && middleware.checkMenuAction('Eliminar') && (
            <Grid item xs={12} sm={4} md={2} lg={2}>
              <Button variant="contained" color="error" onClick={() => handleDelete(data)} fullWidth>
                Eliminar
              </Button>
            </Grid>
          )}

          <Grid item xs={12} sm={4} md={2} lg={2}>
            <Button
              variant="contained"
              color="success"
              onClick={() => handleFormikSubmit(formik)}
              disabled={isLoadingColonias || loadAll}
              fullWidth
            >
              {isEdit ? 'Editar' : 'Agregar'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const LocalDivider = ({ title = '', disabledDivider = false }) => (
  <Grid item xs={12}>
    {!disabledDivider && (
      <Divider
        sx={{
          borderStyle: 'none',
          height: '2px',
          background:
            'repeating-linear-gradient(90deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12) 10px, transparent 10px, transparent 20px)',
          mt: 3,
          mb: 1,
        }}
      />
    )}

    {title !== '' && (
      <Typography variant="h6" component="h3" align="center">
        {title}
      </Typography>
    )}
  </Grid>
);

export default AportantesForm;
